import React, { FC, useState, useContext, useEffect } from "react";
import {
  Container,
  layout,
  tailLayout,
  submitContainer,
} from "./registerStyles";
import { Form, Input, Button, Alert, Card, Avatar } from "antd";
import { useFormik, FormikValues } from "formik";
import { registerRequest } from "api/Api";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Context } from "contexts/Provider";
import logo from "../../assets/logo.png";

interface IRegisterViewProps {}

const RegisterSchema = Yup.object().shape({
  email: Yup.string().email("Invalid E-Mail.").required("E-Mail required."),
  password: Yup.string().required("Password required."),
});

const RegisterPage: FC<IRegisterViewProps> = () => {
  const {
    state: { auth },
  } = useContext(Context);

  const [error, setError] = useState<string>("");

  const history = useHistory();

  useEffect(() => {
    if (auth.isLoggedIn) history.push("/");
  }, []);

  const handleLogin = async (v: FormikValues) => {
    try {
      setError("");
      await registerRequest({
        email: v.email,
        password: v.password,
      });
      history.push("/login");
    } catch (e) {
      setError("Error occured on register.");
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: handleLogin,
  });

  const { values, errors, touched, handleChange, handleSubmit, isSubmitting } =
    formik;

  return (
    <Container>
      <Card>
        <Form
          {...layout}
          name="Register Form"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          <Avatar size={50} src={logo} />
          <h1>Register</h1>
          <Form.Item
            validateStatus={errors.email && touched.email ? "error" : "success"}
            help={errors.email && touched.email ? errors.email : null}
          >
            <Input
              autoFocus
              placeholder="E-mail"
              name="email"
              value={values.email}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            validateStatus={
              errors.password && touched.password ? "error" : "success"
            }
            help={errors.password && touched.password ? errors.password : null}
          >
            <Input.Password
              placeholder="Password"
              name="password"
              value={values.password}
              onChange={handleChange}
            />
          </Form.Item>
          {error ? (
            <Form.Item {...tailLayout}>
              <Alert type="error" message={error} />
            </Form.Item>
          ) : null}
          <Form.Item {...tailLayout} {...submitContainer}>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Register
            </Button>
          </Form.Item>
          <Button
            type="link"
            onClick={() => {
              history.push("/login");
            }}
          >
            Login
          </Button>
        </Form>
      </Card>
    </Container>
  );
};

export default RegisterPage;
