import React, { FC, useContext } from "react";
import { Context } from "contexts/Provider";
import { useHistory } from "react-router-dom";

export interface ICheckAuthProps {
  children: React.ReactNode;
}

const CheckAuth: FC<ICheckAuthProps> = ({ children }: ICheckAuthProps) => {
  const {
    state: { auth },
  } = useContext(Context);

  const history = useHistory();
  if (!auth.isLoggedIn) history.push("/login");
  return <>{children}</>;
};

export default CheckAuth;
