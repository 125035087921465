import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getDirectNewBussinessData } from "api/Api";

const DirectNewBusiness = () => {
  const [data, setData] = useState(initialDataStructure);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await getDirectNewBussinessData();
      setData(response);
    } catch (error) {
      console.error("An error occurred while fetching the data:", error);
      setData(initialDataStructure);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const dataSource = [
    {
      key: "1",
      metric: "Registered Users",
      today: data.userRegisterStatistics.registered_today,
      yesterday: data.userRegisterStatistics.registered_yesterday,
      dayPercentage: data.userRegisterStatistics.registered_day_percentage,
      thisWeek: data.userRegisterStatistics.registered_this_week,
      lastWeek: data.userRegisterStatistics.registered_last_week,
      weekPercentage: data.userRegisterStatistics.registered_week_percentage,
      thisMonth: data.userRegisterStatistics.registered_this_month,
      lastMonth: data.userRegisterStatistics.registered_last_month,
      monthPercentage: data.userRegisterStatistics.registered_month_percentage,
    },
    {
      key: "2",
      metric: "Onboarding (Stage 1) Completed Users",
      today: data.baseOnboardingStatistics.today,
      yesterday: data.baseOnboardingStatistics.yesterday,
      dayPercentage: data.baseOnboardingStatistics.daily_change_pct,
      thisWeek: data.baseOnboardingStatistics.this_week,
      lastWeek: data.baseOnboardingStatistics.last_week,
      weekPercentage: data.baseOnboardingStatistics.weekly_change_pct,
      thisMonth: data.baseOnboardingStatistics.this_month,
      lastMonth: data.baseOnboardingStatistics.last_month,
      monthPercentage: data.baseOnboardingStatistics.monthly_change_pct,
    },
    {
      key: "3",
      metric: "Onboarding (Stage 2) Completed Users",
      today: data.userRegisterStatistics.completed_today,
      yesterday: data.userRegisterStatistics.completed_yesterday,
      dayPercentage:
        data.userRegisterStatistics.register_completed_day_percentage,
      thisWeek: data.userRegisterStatistics.completed_this_week,
      lastWeek: data.userRegisterStatistics.completed_last_week,
      weekPercentage:
        data.userRegisterStatistics.register_completed_week_percentage,
      thisMonth: data.userRegisterStatistics.completed_this_month,
      lastMonth: data.userRegisterStatistics.completed_last_month,
      monthPercentage:
        data.userRegisterStatistics.register_completed_month_percentage,
    },
    {
      key: "4",
      metric: "Number of users InProgress",
      today: data.userStatusStatistics.in_progress_today,
      yesterday: data.userStatusStatistics.in_progress_yesterday,
      dayPercentage:
        data.userStatusStatistics.status_in_progress_day_percentage,
      thisWeek: data.userStatusStatistics.in_progress_this_week,
      lastWeek: data.userStatusStatistics.in_progress_last_week,
      weekPercentage:
        data.userStatusStatistics.status_in_progress_this_week_percentage,
      thisMonth: data.userStatusStatistics.in_progress_this_month,
      lastMonth: data.userStatusStatistics.in_progress_last_month,
      monthPercentage:
        data.userStatusStatistics.status_in_progress_this_month_percentage,
    },
    {
      key: "5",
      metric: "Number of users rejected",
      today: data.userStatusStatistics.rejected_today,
      yesterday: data.userStatusStatistics.rejected_yesterday,
      dayPercentage: data.userStatusStatistics.status_rejected_day_percentage,
      thisWeek: data.userStatusStatistics.rejected_this_week,
      lastWeek: data.userStatusStatistics.rejected_last_week,
      weekPercentage:
        data.userStatusStatistics.status_rejected_this_week_percentage,
      thisMonth: data.userStatusStatistics.rejected_this_month,
      lastMonth: data.userStatusStatistics.rejected_last_month,
      monthPercentage:
        data.userStatusStatistics.status_rejected_this_month_percentage,
    },
    {
      key: "6",
      metric: "Number of users finished",
      today: data.userStatusStatistics.finished_today,
      yesterday: data.userStatusStatistics.finished_yesterday,
      dayPercentage: data.userStatusStatistics.status_finished_day_percentage,
      thisWeek: data.userStatusStatistics.finished_this_week,
      lastWeek: data.userStatusStatistics.finished_last_week,
      weekPercentage:
        data.userStatusStatistics.status_finished_this_week_percentage,
      thisMonth: data.userStatusStatistics.finished_this_month,
      lastMonth: data.userStatusStatistics.finished_last_month,
      monthPercentage:
        data.userStatusStatistics.status_finished_this_month_percentage,
    },
    {
      key: "7",
      metric: "Quotes generated Rooster (TD finished)",
      today: data.quoteStatistics.quote_generated_today,
      yesterday: data.quoteStatistics.quote_generated_yesterday,
      dayPercentage: data.quoteStatistics.quote_generated_day_percentage,
      thisWeek: data.quoteStatistics.quote_generated_week,
      lastWeek: data.quoteStatistics.quote_generated_last_week,
      weekPercentage: data.quoteStatistics.quote_generated_week_percentage,
      thisMonth: data.quoteStatistics.quote_generated_month,
      lastMonth: data.quoteStatistics.quote_generated_last_month,
      monthPercentage: data.quoteStatistics.quote_generated_month_percentage,
    },
    {
      key: "8",
      metric: "Number of policies purchased ",
      today: data.policyStatistics.number_of_today,
      yesterday: data.policyStatistics.number_of_yesterday,
      dayPercentage: data.policyStatistics.number_of_policy_day_percentage,
      thisWeek: data.policyStatistics.number_of_this_week,
      lastWeek: data.policyStatistics.number_of_last_week,
      weekPercentage: data.policyStatistics.number_of_policy_week_percentage,
      thisMonth: data.policyStatistics.number_of_this_month,
      lastMonth: data.policyStatistics.number_of_last_month,
      monthPercentage: data.policyStatistics.number_of_policy_month_percentage,
    },
    {
      key: "9",
      metric: "Value of policies purchased",
      today: data.policyStatistics.value_of_today,
      yesterday: data.policyStatistics.value_of_yesterday,
      dayPercentage: data.policyStatistics.value_of_policy_day_percentage,
      thisWeek: data.policyStatistics.value_of_this_week,
      lastWeek: data.policyStatistics.value_of_last_week,
      weekPercentage: data.policyStatistics.value_of_policy_week_percentage,
      thisMonth: data.policyStatistics.value_of_this_month,
      lastMonth: data.policyStatistics.value_of_last_month,
      monthPercentage: data.policyStatistics.value_of_policy_month_percentage,
    },
    {
      key: "10",
      metric: "AOV",
      today: data.policyStatistics.average_of_today,
      yesterday: data.policyStatistics.average_of_yesterday,
      dayPercentage: data.policyStatistics.aov_day_percentage,
      thisWeek: data.policyStatistics.average_of_this_week,
      lastWeek: data.policyStatistics.average_of_last_week,
      weekPercentage: data.policyStatistics.aov_week_percentage,
      thisMonth: data.policyStatistics.average_of_this_month,
      lastMonth: data.policyStatistics.average_of_last_month,
      monthPercentage: data.policyStatistics.aov_month_percentage,
    },
  ];

  const columns = [
    {
      title: "Metric",
      dataIndex: "metric",
      key: "metric",
      width: 400,
    },
    {
      title: "Today",
      dataIndex: "today",
      key: "today",
    },
    {
      title: "Yesterday",
      dataIndex: "yesterday",
      key: "yesterday",
    },
    {
      title: "Day %",
      dataIndex: "dayPercentage",
      key: "dayPercentage",
    },
    {
      title: "This Week",
      dataIndex: "thisWeek",
      key: "thisWeek",
    },
    {
      title: "Last Week",
      dataIndex: "lastWeek",
      key: "lastWeek",
    },
    {
      title: "Week %",
      dataIndex: "weekPercentage",
      key: "weekPercentage",
    },
    {
      title: "This Month",
      dataIndex: "thisMonth",
      key: "thisMonth",
    },
    {
      title: "Last Month",
      dataIndex: "lastMonth",
      key: "lastMonth",
    },
    {
      title: "Month %",
      dataIndex: "monthPercentage",
      key: "monthPercentage",
    },
  ];

  return (
    <Table
      loading={loading}
      dataSource={data && dataSource}
      columns={columns}
      scroll={{ x: "max-content" }}
      tableLayout="fixed"
      pagination={false}
      bordered
    />
  );
};

export default DirectNewBusiness;

const initialDataStructure = {
  userRegisterStatistics: {
    completed_today: "0",
    completed_yesterday: "0",
    completed_this_week: "0",
    completed_this_month: "0",
    completed_last_week: "0",
    completed_last_month: "1",
    registered_today: "0",
    registered_yesterday: "0",
    registered_this_week: "0",
    registered_this_month: "0",
    registered_last_week: "0",
    registered_last_month: "0",
    register_completed_day_percentage: 0,
    register_completed_month_percentage: 0,
    register_completed_week_percentage: 0,
    registered_day_percentage: 0,
    registered_month_percentage: 0,
    registered_week_percentage: 0,
  },
  baseOnboardingStatistics: {
    today: "0",
    yesterday: "0",
    this_week: "0",
    this_month: "0",
    last_week: "0",
    last_month: "0",
    daily_change_pct: "0%",
    weekly_change_pct: "0%",
    monthly_change_pct: "0%",
  },
  userStatusStatistics: {
    in_progress_today: "0",
    in_progress_yesterday: "0",
    in_progress_this_week: "0",
    in_progress_this_month: "0",
    in_progress_last_week: "0",
    in_progress_last_month: "0",
    rejected_today: "0",
    rejected_yesterday: "0",
    rejected_this_week: "0",
    rejected_this_month: "0",
    rejected_last_week: "0",
    rejected_last_month: "0",
    finished_today: "0",
    finished_yesterday: "0",
    finished_this_week: "0",
    finished_this_month: "0",
    finished_last_week: "0",
    finished_last_month: "0",
    status_in_progress_day_percentage: 0,
    status_in_progress_this_week_percentage: 0,
    status_in_progress_this_month_percentage: 0,
    status_rejected_day_percentage: 0,
    status_rejected_this_week_percentage: 0,
    status_rejected_this_month_percentage: 0,
    status_finished_day_percentage: 0,
    status_finished_this_week_percentage: 0,
    status_finished_this_month_percentage: 0,
  },
  quoteStatistics: {
    quote_generated_today: "0",
    quote_generated_yesterday: "0",
    quote_generated_week: "0",
    quote_generated_month: "0",
    quote_generated_last_week: "0",
    quote_generated_last_month: "0",
    quote_generated_day_percentage: 0,
    quote_generated_week_percentage: 0,
    quote_generated_month_percentage: 0,
  },
  policyStatistics: {
    value_of_today: "0.00",
    number_of_today: 0,
    average_of_today: "0.00",
    value_of_yesterday: "0.00",
    number_of_yesterday: 0,
    average_of_yesterday: "0.00",
    value_of_this_week: "0.00",
    number_of_this_week: 0,
    average_of_this_week: "0.00",
    value_of_this_month: "0.00",
    number_of_this_month: 0,
    average_of_this_month: "0.00",
    value_of_last_week: "0.00",
    number_of_last_week: 0,
    average_of_last_week: "0.00",
    value_of_last_month: "0.00",
    number_of_last_month: 0,
    average_of_last_month: "0.00",
    value_of_policy_day_percentage: 0,
    value_of_policy_week_percentage: 0,
    value_of_policy_month_percentage: 0,
    number_of_policy_day_percentage: 0,
    number_of_policy_week_percentage: 0,
    number_of_policy_month_percentage: 0,
    aov_day_percentage: 0,
    aov_week_percentage: 0,
    aov_month_percentage: 0,
  },
};
