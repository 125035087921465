import React, { FC } from "react";
import { Tooltip } from "antd";
import { LinkOutlined } from "@ant-design/icons";

export interface ILinkPageProps {}

const links = [
  { title: "Askmid", url: "https://ownvehicle.askmid.com/" },
  {
    title: "Move",
    url: "https://dashboard.movesdk.com/?redirect=%2Fadmin%2FappUsers%3FprojectId%3D8132",
  },
  { title: "MOT", url: "https://www.check-mot.service.gov.uk/" },
  {
    title: "Rooster Interactive Dashboard",
    url: "https://rooster-interactive-dashboard.onrender.com/",
  },
];

const wpUrl =
  "https://rooster.auth.eu-west-2.amazoncognito.com/login?client_id=7hvongv0bv4hgj3vkcnsrso3i3&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https%3A%2F%2Fapi.joinrooster.co.uk%2Flogin.php";

const LinksPage: FC<ILinkPageProps> = () => {
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          padding: "40px",
          display: "flex",
          flexDirection: "column",
          width: "30%",
        }}
      >
        <Tooltip title={`Go to W&P`}>
          <a
            href={wpUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              maxWidth: "100px",
              marginBottom: "10px",
            }}
          >
            W&P <LinkOutlined />
          </a>
        </Tooltip>
        {links.map((link, index) => (
          <div key={index} style={{ margin: "10px 0" }}>
            <Tooltip title={`Go to ${link.title}`}>
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "18px" }}
              >
                {link.title} <LinkOutlined />
              </a>
            </Tooltip>
          </div>
        ))}
      </div>
      <iframe
        width="600"
        height="450"
        src="https://lookerstudio.google.com/embed/reporting/cc5965e8-48d4-46ae-9dce-3ab333d1ccc9/page/p_4iztz9xu3c"
        style={{ border: "0", width: "70%", height: "80vh" }}
        allowFullScreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      />
    </div>
  );
};

export default LinksPage;
