import { Dispatch } from "react";
import { reducer as AuthReducer } from "./auth";
import { Action as AuthActions } from "./auth/Types";

import { reducer as AppReducer } from "./app";
import { Action as AppActions } from "./app/Types";

import { RootStateType } from "./RootState";

type CombineActions = {
  Auth: AuthActions;
  App: AppActions;
};

const combineReducers = (reducers: any) => {
  return (state: RootStateType, action: Dispatch<CombineActions>) => {
    return Object.keys(reducers).reduce((acc: any, prop: string) => {
      return {
        ...acc,
        ...reducers[prop]({ [prop]: acc[prop] }, action),
      };
    }, state);
  };
};
export const rootReducer = combineReducers({
  auth: AuthReducer,
  app: AppReducer,
});
