import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getTotalData } from "api/Api"; // API çağrısı buraya eklenecek

const TotalPage = () => {
  const initialDataStructure = {
    policyStatistics: {
      value_of_yesterday: "0.00",
      number_of_yesterday: 0,
      average_of_yesterday: "0.00",
      value_of_today: "0.00",
      number_of_today: 0,
      average_of_today: "0.00",
      value_of_this_week: "0.00",
      number_of_this_week: 0,
      average_of_this_week: "0.00",
      value_of_this_month: "0.00",
      number_of_this_month: 0,
      average_of_this_month: "0.00",
      value_of_last_week: "0",
      number_of_last_week: 0,
      average_of_last_week: "0.00",
      value_of_last_month: "0",
      number_of_last_month: 0,
      average_of_last_month: "0.00",
      value_of_policy_day_percentage: 0,
      value_of_policy_month_percentage: 0,
      value_of_policy_week_percentage: 0,
      number_of_policy_day_percentage: 0,
      number_of_policy_month_percentage: 0,
      number_of_policy_week_percentage: 0,
      aov_day_percentage: 0,
      aov_month_percentage: 0,
      aov_week_percentage: 0,
    },
    totalQuoteStats: {
      quote_offered_today: "0",
      quote_offered_yesterday: "0",
      quote_offered_this_week: "0",
      quote_offered_this_month: "0",
      quote_offered_last_week: "0",
      quote_offered_last_month: "0",
      quote_offered_day_percentage: 0,
      quote_offered_this_week_percentage: 0,
      quote_offered_this_month_percentage: 0,
    },
  };

  const [data, setData] = useState(initialDataStructure);

  const getData = async () => {
    try {
      const response = await getTotalData();
      setData(response);
    } catch (error) {
      console.error("An error occurred while fetching the data:", error);
      setData(initialDataStructure);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const dataSource = [
    {
      key: "1",
      metric: "Quotes offered",
      today: data.totalQuoteStats.quote_offered_today,
      yesterday: data.totalQuoteStats.quote_offered_yesterday,
      dayPercentage: data.totalQuoteStats.quote_offered_day_percentage,
      thisWeek: data.totalQuoteStats.quote_offered_this_week,
      lastWeek: data.totalQuoteStats.quote_offered_last_week,
      weekPercentage: data.totalQuoteStats.quote_offered_this_week_percentage,
      thisMonth: data.totalQuoteStats.quote_offered_this_month,
      lastMonth: data.totalQuoteStats.quote_offered_last_month,
      monthPercentage: data.totalQuoteStats.quote_offered_this_month_percentage,
    },
    {
      key: "2",
      metric: "Number of policies purchased ",
      today: data.policyStatistics.number_of_today,
      yesterday: data.policyStatistics.number_of_yesterday,
      dayPercentage: data.policyStatistics.number_of_policy_day_percentage,
      thisWeek: data.policyStatistics.number_of_this_week,
      lastWeek: data.policyStatistics.number_of_last_week,
      weekPercentage: data.policyStatistics.number_of_policy_week_percentage,
      thisMonth: data.policyStatistics.number_of_this_month,
      lastMonth: data.policyStatistics.number_of_last_month,
      monthPercentage: data.policyStatistics.number_of_policy_month_percentage,
    },
    {
      key: "3",
      metric: "Value of policies purchased ",
      today: data.policyStatistics.value_of_today,
      yesterday: data.policyStatistics.value_of_yesterday,
      dayPercentage: data.policyStatistics.value_of_policy_day_percentage,
      thisWeek: data.policyStatistics.value_of_this_week,
      lastWeek: data.policyStatistics.value_of_last_week,
      weekPercentage: data.policyStatistics.value_of_policy_week_percentage,
      thisMonth: data.policyStatistics.value_of_this_month,
      lastMonth: data.policyStatistics.value_of_last_month,
      monthPercentage: data.policyStatistics.value_of_policy_month_percentage,
    },
    {
      key: "4",
      metric: "AOV",
      today: data.policyStatistics.average_of_today,
      yesterday: data.policyStatistics.average_of_yesterday,
      dayPercentage: data.policyStatistics.aov_day_percentage,
      thisWeek: data.policyStatistics.average_of_this_week,
      lastWeek: data.policyStatistics.average_of_last_week,
      weekPercentage: data.policyStatistics.aov_week_percentage,
      thisMonth: data.policyStatistics.average_of_this_month,
      lastMonth: data.policyStatistics.average_of_last_month,
      monthPercentage: data.policyStatistics.aov_month_percentage,
    },
  ];

  const columns = [
    {
      title: "Metric",
      dataIndex: "metric",
      key: "metric",
      width: 400,
    },
    {
      title: "Today",
      dataIndex: "today",
      key: "today",
    },
    {
      title: "Yesterday",
      dataIndex: "yesterday",
      key: "yesterday",
    },
    {
      title: "Day %",
      dataIndex: "dayPercentage",
      key: "dayPercentage",
    },
    {
      title: "This Week",
      dataIndex: "thisWeek",
      key: "thisWeek",
    },
    {
      title: "Last Week",
      dataIndex: "lastWeek",
      key: "lastWeek",
    },
    {
      title: "Week %",
      dataIndex: "weekPercentage",
      key: "weekPercentage",
    },
    {
      title: "This Month",
      dataIndex: "thisMonth",
      key: "thisMonth",
    },
    {
      title: "Last Month",
      dataIndex: "lastMonth",
      key: "lastMonth",
    },
    {
      title: "Month %",
      dataIndex: "monthPercentage",
      key: "monthPercentage",
    },
  ];

  return (
    <Table
      dataSource={data && dataSource}
      columns={columns}
      scroll={{ x: "max-content" }}
      tableLayout="fixed"
      pagination={false}
      bordered
    />
  );
};

export default TotalPage;
