import React, { FC, useEffect, useState } from "react";
import { listAnswers, downloadAnswers } from "api/Api";
import { Row, Col, Table, Button, Input, notification } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Answer } from "../../types/Answer";
import { CSVDownload } from "react-csv";

import { SaveOutlined } from "@ant-design/icons";
import moment from "moment";

export interface IAnswersPageProps {}

const handleCopy = (text: string, type: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      notification.success({
        message: "Success",
        description: `${type} copied to clipboard.`,
        duration: 2,
      });
    })
    .catch((error) => {
      console.error("Failed to copy text:", error);
    });
};

const AnswersPage: FC<IAnswersPageProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [answers, setAnswers] = useState<Array<Answer>>([]);
  const [csvData, setCsvData] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [searchVal, setSearchVal] = useState<string>("");
  const [startDate, setStartDate] = useState(
    moment().subtract(2, "months").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(new Date());

  const func = async ({
    page,
    query,
    start_date,
    end_date,
  }: {
    page?: number;
    query?: string;
    start_date?: Date;
    end_date?: Date;
  }) => {
    setLoading(true);
    const result = await listAnswers({
      page,
      query,
      start_date: start_date,
      end_date: end_date,
    });
    setLoading(false);
    setCount(result.data.total_results);
    setAnswers(
      result.data.results.map(
        ({
          id,
          user_email,
          user_first_name,
          user_last_name,
          question_text,
          answer_text,
          custom_value,
        }: any) => {
          return {
            key: id,
            user_email,
            user_first_name,
            user_last_name,
            question_text,
            answer_text,
            custom_value,
          };
        }
      )
    );
  };

  useEffect(() => {
    func({});
  }, []);

  return (
    <Row>
      <Col span={20} offset={2}>
        <Row style={{ marginBottom: 10 }}>
          <Col span={6}>
            <Input.Search
              placeholder="Search"
              onSearch={(val) => {
                setSearchVal(val);
                func({
                  query: val,
                  start_date: startDate,
                  end_date: endDate,
                });
              }}
            />
          </Col>
          <Col span={8}></Col>
          <Col span={4}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
            />
          </Col>
          <Col span={1}>
            <h4>to</h4>
          </Col>
          <Col span={4}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date: Date) => setEndDate(date)}
            />
          </Col>
          <Col span={1}>
            {csvData && <CSVDownload data={csvData} target="_blank" />}
            <Button
              style={{ float: "right" }}
              onClick={async () => {
                const csv = await downloadAnswers({
                  start_date: startDate,
                  end_date: endDate,
                });
                setCsvData(csv.data);
              }}
            >
              <SaveOutlined />
            </Button>
          </Col>
        </Row>
        <Table
          loading={loading}
          pagination={{
            showSizeChanger: false,
            pageSize: 10,
            total: count,
            onChange: (page) => {
              func({
                page,
                query: searchVal,
                start_date: startDate,
                end_date: endDate,
              });
            },
          }}
          dataSource={answers}
          scroll={{ x: "max-content" }}
          tableLayout="fixed"
          columns={[
            {
              title: "E-Mail",
              dataIndex: "user_email",
              fixed: true,
              width: 250,
              // eslint-disable-next-line react/display-name
              render: (text: string) => (
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "200px",
                    cursor: "pointer",
                  }}
                  title={text}
                  onDoubleClick={() => handleCopy(text, "Email")}
                >
                  {text}
                </div>
              ),
            },
            {
              title: "First Name",
              dataIndex: "user_first_name",
              width: 150,
              // eslint-disable-next-line react/display-name
              render: (text: string) => (
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "200px",
                    cursor: "pointer",
                  }}
                  title={text}
                  onDoubleClick={() => handleCopy(text, "Email")}
                >
                  {text}
                </div>
              ),
            },
            {
              title: "Last Name",
              dataIndex: "user_last_name",
              width: 150,
              // eslint-disable-next-line react/display-name
              render: (text: string) => (
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "200px",
                    cursor: "pointer",
                  }}
                  title={text}
                  onDoubleClick={() => handleCopy(text, "Email")}
                >
                  {text}
                </div>
              ),
            },
            {
              title: "Question",
              dataIndex: "question_text",
              width: 400,
              // eslint-disable-next-line react/display-name
              render: (text: string) => (
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "400px",
                    cursor: "pointer",
                  }}
                  title={text}
                  onDoubleClick={() => handleCopy(text, "Email")}
                >
                  {text}
                </div>
              ),
            },
            {
              title: "Answer",
              width: 200,
              render: function col(_text: any, res: any) {
                return <span>{res.answer_text || res.custom_value}</span>;
              },
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default AnswersPage;
