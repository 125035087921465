import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
  width: 30%;
  min-width: 320px;
  max-width: 475px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const layout = {
  wrapperCol: { offset: 4, span: 16 },
};

export const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

export const submitContainer = {
  style: { marginBottom: 10 },
};
