import React, { FC, useState } from "react";
import { Row, Col, Input, Button, Select, Table } from "antd";
import { getMOTFailure } from "api/Api"; // Bu fonksiyonunuzun doğru bir şekilde veri döndürdüğünü varsayıyorum.
const { Option } = Select;

export interface IMOTFailurePageProps {}

const MOTFailurePage: FC<IMOTFailurePageProps> = () => {
  const [make, setMake] = useState("VOLKSWAGEN");
  const [model, setModel] = useState("POLO");
  const [age, setAge] = useState(5);
  const [mileage, setMileage] = useState(188000);
  const [failures, setFailures] = useState([]);
  const [counts, setCounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const onClickButton = async () => {
    try {
      setLoading(true);
      const data = await getMOTFailure({ make, model, age, mileage });
      setFailures(data.motFailureList);
      setCounts(data.motCountList);
    } catch (error) {
      console.log("Error while retrieving MOT Failure", error);
    } finally {
      setLoading(false);
    }
  };

  const failureColumns = [
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Reason for Failure",
      dataIndex: "rfr_desc",
      key: "rfr_desc",
    },
    {
      title: "Ratio (%)",
      dataIndex: "Ratio (%)",
      key: "ratio",
    },
  ];

  const countColumns = [
    {
      title: "Test Result",
      dataIndex: "test_result",
      key: "test_result",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Ratio (%)",
      dataIndex: "Ratio (%)",
      key: "ratio",
    },
  ];

  return (
    <Row>
      <Col style={{ marginTop: 20 }} span={14} offset={5}>
        <Input
          placeholder="Make"
          value={make}
          onChange={(e) => setMake(e.target.value)}
          style={{ marginBottom: 10 }}
        />
        <Input
          placeholder="Model"
          value={model}
          onChange={(e) => setModel(e.target.value)}
          style={{ marginBottom: 10 }}
        />
        <Select
          placeholder="Age"
          value={age}
          onChange={(value) => setAge(value)}
          style={{ width: "100%", marginBottom: 10 }}
        >
          {Array.from({ length: 29 }, (_, i) => i + 1).map((age) => (
            <Option key={age} value={age}>
              {age}
            </Option>
          ))}
        </Select>
        <Input
          placeholder="Mileage"
          value={mileage}
          onChange={(e) => {
            const inputMileage =
              e.target.value === "" ? 0 : parseInt(e.target.value, 10);
            setMileage(inputMileage);
          }}
          style={{ marginBottom: 10 }}
        />
        <Button
          type="primary"
          onClick={onClickButton}
          disabled={loading}
          style={{ marginBottom: 20 }}
        >
          Get MOT Failure
        </Button>
      </Col>
      <Col style={{ marginTop: 20 }} span={14} offset={5}>
        <h2>Failure Reasons</h2>
        <Table
          dataSource={failures}
          columns={failureColumns}
          rowKey="rfr_desc"
          pagination={false}
        />
      </Col>
      <Col style={{ marginTop: 20 }} span={14} offset={5}>
        <h2>Test Results</h2>
        <Table
          dataSource={counts}
          columns={countColumns}
          rowKey="test_result"
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default MOTFailurePage;
