import React, { FC, useEffect, useState } from "react";
import {
  listUsers,
  updateUserStatus,
  createQuoteRequest,
  deleteUserById,
  updateUserById,
  updateVehicleData,
} from "api/Api";
import {
  Row,
  Col,
  Table,
  Input,
  Select,
  Button,
  Modal,
  DatePicker,
  Collapse,
  Space,
  Typography,
} from "antd";
import { User } from "../../types/User";
import {
  SaveOutlined,
  DeleteOutlined,
  DiffOutlined,
  ExclamationCircleFilled,
  FundViewOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { CSVDownload } from "react-csv";
import moment from "moment";
import { notification } from "antd";
import ActionButton from "components/table/renderActionButton/RenderActionButton";
import SortableHeader from "components/table/sortableTableHeader/SortableTableHeader";

const { confirm } = Modal;
const { Panel } = Collapse;
const { Text } = Typography;

const handleCopy = (text: string, type: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      notification.success({
        message: "Success",
        description: `${type} copied to clipboard.`,
        duration: 2,
      });
    })
    .catch((error) => {
      console.error("Failed to copy text:", error);
    });
};
export interface IUsersPageProps {}

const UsersPage: FC<IUsersPageProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<Array<User>>([]);
  const [count, setCount] = useState<number>(0);
  const [searchVal, setSearchVal] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [csvData, setCsvData] = useState<string>("");
  const [sortStates, setSortStates] = useState({ field: null, order: null });
  const [filter, setFilter] = useState({});
  const [endorsements, setEndorsements] = useState(null);
  const [isEndorsementsModalVisible, setIsEndorsementsModalVisible] =
    useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedRowKeysForUpdate, setSelectedRowKeysForUpdate] = useState([]);

  const rowSelection = {
    selectedRowKeys: selectedRowKeysForUpdate,
    onChange: (newSelectedRowKeys: any) => {
      setSelectedRowKeysForUpdate(newSelectedRowKeys);
    },
  };

  const updateMultipleUserStatus = async (newStatus: any) => {
    setLoading(true);
    try {
      await Promise.all(
        selectedRowKeysForUpdate.map((userId) =>
          updateUserStatus({ id: userId, status: newStatus })
        )
      );
      notification.success({
        message: "Success",
        description: "Users successfully updated.",
        duration: 2,
      });
      setSelectedRowKeysForUpdate([]);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error while updating users.",
        duration: 2,
      });
    } finally {
      setLoading(false);
      func({ page, query: searchVal, filter, sorter: sortStates });
    }
  };

  const func = async ({
    page = 0,
    limit,
    query,
    download,
    filter,
    sorter,
  }: {
    page?: number;
    limit?: number;
    query?: string;
    filter?: any;
    download?: boolean;
    sorter?: any;
  }) => {
    if (download) {
      page = undefined;
      query = undefined;
      limit = undefined;
    }
    setLoading(true);

    const result = await listUsers({
      page,
      query,
      limit,
      download,
      sorter,
      filter,
    });
    if (download) {
      setLoading(false);
      window.open(result.data.url);
    } else {
      setLoading(false);
      setCount(result.data.total_results);
      setUsers(
        result.data.results.map(
          ({
            id,
            email,
            first_name,
            last_name,
            user_status,
            test_drive_started_at,
            onboarding_completed_at,
            test_drive_provider,
            combined_score,
            industry,
            industry_id,
            occupation,
            occupation_id,
            vrm,
            make,
            model,
            place_of_birth,
            dln,
            licence_type,
            entitlement_array,
            full_address,
            postal_code,
            licence_address,
            endorsements,
            date_of_birth,
          }: any) => {
            let validFromDateF = null;

            const entitlements = Array.isArray(entitlement_array)
              ? entitlement_array
              : JSON.parse(entitlement_array);

            if (entitlements) {
              const entitlementF = entitlements.find(
                (entitlement: any) =>
                  entitlement.type === "F" && entitlement.code === "F"
              );

              if (entitlementF && entitlementF.validFrom) {
                validFromDateF = entitlementF.validFrom;
              }
            }

            return {
              key: id,
              id,
              email,
              first_name,
              last_name,
              test_drive_provider,
              onboarding_completed_at,
              user_status,
              combined_score,
              industry,
              industry_id,
              occupation,
              occupation_id,
              vrm,
              make,
              model,
              place_of_birth,
              dln,
              licence_type,
              validFromDateF,
              full_address,
              postal_code,
              licence_address,
              endorsements,
              date_of_birth,
              test_drive_started_at: moment(test_drive_started_at)
                .format("DD/MM/YYYY HH:mm:ss")
                .toString(),
            };
          }
        )
      );
    }
  };

  useEffect(() => {
    func({ page, limit, query: searchVal, filter, sorter: sortStates });
  }, [page, limit, filter, sortStates, searchVal]);

  const createQuote = async (userId: string) => {
    setLoading(true);
    try {
      const result: any = await createQuoteRequest({ userId });
      if (result?.status === 200) {
        notification.success({
          message: "Success",
          description: "Quote created successfully.",
          duration: 2,
        });
      } else {
        notification.error({
          message: "Error",
          description: "Error while creating quote.",
          duration: 2,
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error while creating quote.",
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (userId: string) => {
    return new Promise(async (resolve, reject) => {
      setLoading(true);
      try {
        const result: any = await deleteUserById({ userId });
        if (result?.status === 200) {
          resolve(result);
        } else {
          throw new Error("Failed to delete user");
        }
      } catch (error) {
        reject(error);
      } finally {
        setLoading(false);
      }
    });
  };

  const handleHeaderClick = (columnName: string) => {
    let newOrder;
    if (sortStates.field === columnName) {
      if (sortStates.order === "ascend") {
        newOrder = "descend";
      } else if (sortStates.order === "descend") {
        newOrder = null;
      } else {
        newOrder = "ascend";
      }
    } else {
      newOrder = "ascend";
    }

    setSortStates({ field: newOrder ? columnName : null, order: newOrder });
  };

  const getSortIndicator = (columnName: string) => {
    if (sortStates.field === columnName) {
      if (sortStates.order === "ascend") {
        return "↑";
      } else if (sortStates.order === "descend") {
        return "↓";
      }
    }
    return "-";
  };

  const getSortDescription = (columnName: string) => {
    if (sortStates.field === columnName) {
      switch (sortStates.order) {
        case "ascend":
          return "Sort descending";
        case "descend":
          return "Remove sorting";
        default:
          return "Sort ascending";
      }
    } else {
      return "Sort ascending";
    }
  };

  const showDeleteConfirm = (userId: string) => {
    confirm({
      title: "Are you sure delete this user?",
      icon: <ExclamationCircleFilled />,
      content: "This action cannot be undone!",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteUser(userId)
          .then(() => {
            notification.success({
              message: "Success",
              description: "User deleted successfully.",
              duration: 2,
            });
            func({ page, limit, query: searchVal, filter, sorter: sortStates });
          })
          .catch(() => {
            notification.error({
              message: "Error",
              description: "Error while deleting user.",
              duration: 2,
            });
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDateChange = (date: any, dateString: any) => {
    setFilter((prev) => ({
      ...prev,
      onboarding_completed_at: dateString,
    }));
  };

  const EndorsementsModal = ({ endorsements, visible, onClose }: any) => (
    <Modal
      style={{ top: 30 }}
      title="Endorsements Details"
      visible={visible}
      onOk={onClose}
      onCancel={onClose}
    >
      <div style={{ overflow: "scroll", maxHeight: 600 }}>
        <Collapse>
          {endorsements &&
            endorsements.map((endorsement: any, index: any) => (
              <Panel header={`Endorsement ${index + 1}`} key={index}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  {Object.entries(endorsement).map(([key, value]) => (
                    <p key={key}>
                      <strong>{key}:</strong> {value ? value.toString() : "N/A"}
                    </p>
                  ))}
                </div>
              </Panel>
            ))}
        </Collapse>
      </div>
    </Modal>
  );

  EndorsementsModal.displayName = "EndorsementsModal";

  const updateDateOfBirth = async (record: any) => {
    if (record.newDateOfBirth) {
      setLoading(true);
      try {
        const result = await updateUserById(record.id, {
          date_of_birth: record.newDateOfBirth.format("YYYY-MM-DD"),
        });
        if (result?.status === 200) {
          notification.success({
            message: "Success",
            description: "Date of Birth updated successfully.",
            duration: 2,
          });

          func({ page, query: searchVal, filter, sorter: sortStates });
        } else {
          throw new Error("Update failed");
        }
      } catch (error) {
        notification.error({
          message: "Error",
          description: "Error while updating Date of Birth.",
          duration: 2,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const UpdateVehicleModal = ({ visible, onClose }: any) => {
    const [vrm, setVrm] = useState("");
    const [updateLoading, setUpdateLoading] = useState(false);

    const handleSubmit = async () => {
      try {
        setUpdateLoading(true);
        const result = await updateVehicleData({
          vrm,
          user_id: selectedUserId,
        });
        if (result?.status === 200) {
          onClose();
          func({ page, limit, query: searchVal, filter, sorter: sortStates });
          notification.success({
            message: "Success",
            description: "User vehicle data updated successfully.",
            duration: 2,
          });
        }
      } catch (error) {
        notification.error({
          message: "Error",
          description: "Error while updating user vehicle data.",
          duration: 2,
        });
        onClose();
      } finally {
        setUpdateLoading(false);
      }
    };

    return (
      <Modal
        title="Update Vehicle Data"
        visible={visible}
        onOk={handleSubmit}
        onCancel={onClose}
        confirmLoading={updateLoading}
        footer={[
          <Button key="back" onClick={onClose}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={updateLoading}
            onClick={handleSubmit}
            disabled={updateLoading}
          >
            Update
          </Button>,
        ]}
      >
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Text>
            <strong>Selected Email:</strong> {selectedEmail}
          </Text>
          <Text>
            <strong>Selected User ID:</strong> {selectedUserId}
          </Text>
          <Input
            value={vrm}
            onChange={(e) => setVrm(e.target.value)}
            placeholder="Enter VRM"
          />
        </Space>
      </Modal>
    );
  };

  UpdateVehicleModal.displayName = "UpdateVehicleModal";

  const handleClickUpdateModal = (userId: any, data: any) => {
    setSelectedUserId(userId);
    setSelectedEmail(data.email);
    setIsUpdateModalVisible(true);
  };

  return (
    <>
      <EndorsementsModal
        endorsements={endorsements}
        visible={isEndorsementsModalVisible}
        onClose={() => setIsEndorsementsModalVisible(false)}
      />

      <UpdateVehicleModal
        visible={isUpdateModalVisible}
        onClose={() => setIsUpdateModalVisible(false)}
      />
      <Row>
        <Col span={24}>
          <Row style={{ marginBottom: 10 }}>
            <Col span={6}>
              <Input.Search
                placeholder="Search"
                onSearch={(val) => {
                  setSearchVal(val);
                }}
              />
            </Col>
            <Col span={18} style={{ textAlign: "right" }}>
              {selectedRowKeysForUpdate.length > 0 && (
                <Select
                  placeholder="Bulk Update Status"
                  style={{ marginRight: 8 }}
                  onChange={(value) => updateMultipleUserStatus(value)}
                >
                  <Select.Option value="in_progress">In Progress</Select.Option>
                  <Select.Option value="finished">Finished</Select.Option>
                  <Select.Option value="rejected">Rejected</Select.Option>
                </Select>
              )}
              {csvData && <CSVDownload data={csvData} target="_blank" />}
              <Button
                disabled={loading}
                style={{ marginRight: 8 }}
                onClick={async () => {
                  func({ download: true });
                }}
              >
                <SaveOutlined />
              </Button>
            </Col>
          </Row>
          <Table
            rowSelection={rowSelection}
            loading={loading}
            pagination={{
              showSizeChanger: true,
              pageSize: limit,
              total: count,
              onChange: (page, pageSize) => {
                setPage(page);
                setLimit(pageSize);
              },
            }}
            dataSource={users}
            scroll={{ x: "max-content" }}
            tableLayout="fixed"
            columns={[
              {
                title: "User Id",
                dataIndex: "id",
                // eslint-disable-next-line react/display-name
                render: (text: string) => (
                  <div
                    style={{
                      wordWrap: "break-word",
                      wordBreak: "break-word",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "140px",
                      textOverflow: "ellipsis",
                      cursor: "pointer",
                    }}
                    onDoubleClick={() => handleCopy(text, "User ID")}
                    title={text}
                  >
                    {text}
                  </div>
                ),
                fixed: true,
              },
              {
                title: "E-mail",
                dataIndex: "email",
                width: 250,
                fixed: true,
                // eslint-disable-next-line react/display-name
                render: (text) => (
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "250px",
                      cursor: "pointer",
                    }}
                    title={text}
                  >
                    {text}
                  </div>
                ),
              },

              {
                title: "First Name",
                dataIndex: "first_name",
                width: 180,
                // eslint-disable-next-line react/display-name
                render: (text) => (
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "180px",
                      cursor: "pointer",
                    }}
                    title={text}
                  >
                    {text}
                  </div>
                ),
              },
              {
                title: "Last Name",
                dataIndex: "last_name",
                width: 180,
                // eslint-disable-next-line react/display-name
                render: (text) => (
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "180px",
                      cursor: "pointer",
                    }}
                    title={text}
                  >
                    {text}
                  </div>
                ),
              },
              {
                title: "Date of Birth",
                dataIndex: "date_of_birth",
                // eslint-disable-next-line react/display-name
                render: (_, record) => (
                  <>
                    <DatePicker
                      defaultValue={moment(record.date_of_birth)}
                      format="YYYY-MM-DD"
                      onChange={(date) => {
                        record.newDateOfBirth = date;
                      }}
                      allowClear={false}
                    />
                    <Button
                      icon={<SaveOutlined />}
                      onClick={() => updateDateOfBirth(record)}
                      style={{ marginLeft: 8 }}
                    />
                  </>
                ),
              },
              {
                title: "Place of Birth",
                dataIndex: "place_of_birth",
                // eslint-disable-next-line react/display-name
                filterDropdown: ({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                }: any) => (
                  <div style={{ padding: 8 }}>
                    <Input
                      placeholder={`Search Place Of Birth`}
                      value={selectedKeys[0]}
                      onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                      }
                      onPressEnter={() => confirm()}
                      style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            placeOfBirth: selectedKeys[0],
                          };
                        });
                        confirm();
                      }}
                      size="small"
                      style={{ width: 90, marginRight: 8 }}
                    >
                      Filter
                    </Button>
                    <Button
                      onClick={() => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            placeOfBirth: "",
                          };
                        });
                        clearFilters();
                      }}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Reset
                    </Button>
                  </div>
                ),
              },
              {
                title: (
                  <SortableHeader
                    title="Start Time"
                    columnFullname="Start Time"
                    dataIndex="test_drive_started_at"
                    handleHeaderClick={handleHeaderClick}
                    getSortIndicator={getSortIndicator}
                    getSortDescription={getSortDescription}
                  />
                ),
                dataIndex: "test_drive_started_at",
                width: 250,
              },
              {
                title: "Endorsements",
                dataIndex: "endorsements",
                width: 100,
                align: "center",
                // eslint-disable-next-line react/display-name
                render: (_: any, record: any) => (
                  <Button
                    onClick={() => {
                      setEndorsements(JSON.parse(record.endorsements));
                      setIsEndorsementsModalVisible(true);
                    }}
                  >
                    <FundViewOutlined />
                  </Button>
                ),
              },
              {
                title: "Licence Type",
                dataIndex: "licence_type",
                width: 175,
                // eslint-disable-next-line react/display-name
                filterDropdown: ({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                }: any) => (
                  <div style={{ padding: 8 }}>
                    <Input
                      placeholder={`Search Licence Type`}
                      value={selectedKeys[0]}
                      onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                      }
                      onPressEnter={() => confirm()}
                      style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            licenceType: selectedKeys[0],
                          };
                        });
                        confirm();
                      }}
                      size="small"
                      style={{ width: 90, marginRight: 8 }}
                    >
                      Filter
                    </Button>
                    <Button
                      onClick={() => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            licenceType: "",
                          };
                        });
                        clearFilters();
                      }}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Reset
                    </Button>
                  </div>
                ),
              },
              {
                title: "Valid From",
                dataIndex: "validFromDateF",
                width: 175,
              },
              {
                title: "Dln",
                dataIndex: "dln",
                width: 175,
                // eslint-disable-next-line react/display-name
                filterDropdown: ({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                }: any) => (
                  <div style={{ padding: 8 }}>
                    <Input
                      placeholder={`Search DLN`}
                      value={selectedKeys[0]}
                      onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                      }
                      onPressEnter={() => confirm()}
                      style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            dln: selectedKeys[0],
                          };
                        });
                        confirm();
                      }}
                      size="small"
                      style={{ width: 90, marginRight: 8 }}
                    >
                      Filter
                    </Button>
                    <Button
                      onClick={() => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            dln: "",
                          };
                        });
                        clearFilters();
                      }}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Reset
                    </Button>
                  </div>
                ),
              },
              {
                title: "VRM",
                dataIndex: "vrm",
                // eslint-disable-next-line react/display-name
                filterDropdown: ({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                }: any) => (
                  <div style={{ padding: 8 }}>
                    <Input
                      placeholder={`Search VRM`}
                      value={selectedKeys[0]}
                      onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                      }
                      onPressEnter={() => confirm()}
                      style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            vrm: selectedKeys[0],
                          };
                        });
                        confirm();
                      }}
                      size="small"
                      style={{ width: 90, marginRight: 8 }}
                    >
                      Filter
                    </Button>
                    <Button
                      onClick={() => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            vrm: "",
                          };
                        });
                        clearFilters();
                      }}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Reset
                    </Button>
                  </div>
                ),
              },
              {
                title: "Make",
                dataIndex: "make",
                // eslint-disable-next-line react/display-name
                filterDropdown: ({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                }: any) => (
                  <div style={{ padding: 8 }}>
                    <Input
                      placeholder={`Search Make`}
                      value={selectedKeys[0]}
                      onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                      }
                      onPressEnter={() => confirm()}
                      style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            make: selectedKeys[0],
                          };
                        });
                        confirm();
                      }}
                      size="small"
                      style={{ width: 90, marginRight: 8 }}
                    >
                      Filter
                    </Button>
                    <Button
                      onClick={() => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            make: "",
                          };
                        });
                        clearFilters();
                      }}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Reset
                    </Button>
                  </div>
                ),
              },
              {
                title: "Model",
                dataIndex: "model",
                // eslint-disable-next-line react/display-name
                filterDropdown: ({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                }: any) => (
                  <div style={{ padding: 8 }}>
                    <Input
                      placeholder={`Search Model`}
                      value={selectedKeys[0]}
                      onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                      }
                      onPressEnter={() => confirm()}
                      style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            model: selectedKeys[0],
                          };
                        });
                        confirm();
                      }}
                      size="small"
                      style={{ width: 90, marginRight: 8 }}
                    >
                      Filter
                    </Button>
                    <Button
                      onClick={() => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            model: "",
                          };
                        });
                        clearFilters();
                      }}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Reset
                    </Button>
                  </div>
                ),
              },
              {
                title: "TD Provider",
                dataIndex: "test_drive_provider",
                align: "center",
                width: 130,
                // eslint-disable-next-line react/display-name
                filterDropdown: ({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                }: any) => (
                  <div style={{ padding: 8 }}>
                    <Select
                      value={selectedKeys[0]}
                      onChange={(e: any) => {
                        setSelectedKeys(e ? [e] : []);
                        confirm();
                        setFilter((prev) => {
                          return {
                            ...prev,
                            testDriveProvider: e,
                          };
                        });
                      }}
                      style={{ width: 188, marginBottom: 8, display: "block" }}
                    >
                      <Select.Option value="amodo">Amodo</Select.Option>
                      <Select.Option value="move">Move</Select.Option>
                    </Select>
                    <Button
                      type="primary"
                      onClick={() => confirm()}
                      size="small"
                      style={{ width: 90, marginRight: 8 }}
                    >
                      Filter
                    </Button>
                    <Button
                      onClick={() => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            testDriveProvider: "",
                          };
                        });
                        clearFilters();
                      }}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Reset
                    </Button>
                  </div>
                ),
              },
              {
                title: "Industry",
                dataIndex: "industry",
                width: 150,
                align: "center",
                // eslint-disable-next-line react/display-name
                filterDropdown: ({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                }: any) => (
                  <div style={{ padding: 8 }}>
                    <Input
                      value={selectedKeys[0]}
                      onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                      }}
                      onPressEnter={() => confirm()}
                      style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        confirm();
                        setFilter((prev) => {
                          return {
                            ...prev,
                            industry: selectedKeys[0],
                          };
                        });
                      }}
                      size="small"
                      style={{ width: 90, marginRight: 8 }}
                    >
                      Filter
                    </Button>
                    <Button
                      onClick={() => {
                        setSelectedKeys([]);
                        setFilter((prev) => {
                          return {
                            ...prev,
                            industry: "",
                          };
                        });
                        clearFilters();
                      }}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Reset
                    </Button>
                  </div>
                ),
              },

              //occupation_code
              {
                title: "Occupation",
                dataIndex: "occupation",
                width: 150,
                align: "center",
                // eslint-disable-next-line react/display-name
                filterDropdown: ({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                }: any) => (
                  <div style={{ padding: 8 }}>
                    <Input
                      value={selectedKeys[0]}
                      onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                      }}
                      onPressEnter={() => confirm()}
                      style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        confirm();
                        setFilter((prev) => {
                          return {
                            ...prev,
                            occupation: selectedKeys[0],
                          };
                        });
                      }}
                      size="small"
                      style={{ width: 90, marginRight: 8 }}
                    >
                      Filter
                    </Button>
                    <Button
                      onClick={() => {
                        setSelectedKeys([]);
                        setFilter((prev) => {
                          return {
                            ...prev,
                            occupation: "",
                          };
                        });
                        clearFilters();
                      }}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Reset
                    </Button>
                  </div>
                ),
              },

              { title: "Score", dataIndex: "combined_score", width: 30 },
              {
                title: "Status",
                dataIndex: "status",
                filters: [
                  { text: "In Progress", value: "in_progress" },
                  { text: "Finished", value: "finished" },
                  { text: "Rejected", value: "rejected" },
                ],
                width: 160,
                // eslint-disable-next-line react/display-name, react/prop-types
                filterDropdown: ({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                }: any) => (
                  <div style={{ padding: 8 }}>
                    <Select
                      value={selectedKeys[0]}
                      onChange={(e: any) => {
                        setSelectedKeys(e ? [e] : []);
                        confirm();
                        setFilter((prev) => {
                          return {
                            ...prev,
                            status: e,
                          };
                        });
                      }}
                      style={{ width: 188, marginBottom: 8, display: "block" }}
                    >
                      <Select.Option value="in_progress">
                        In Progress
                      </Select.Option>
                      <Select.Option value="finished">Finished</Select.Option>
                      <Select.Option value="rejected">Rejected</Select.Option>
                    </Select>
                    <Button
                      type="primary"
                      onClick={() => confirm()}
                      size="small"
                      style={{ width: 90, marginRight: 8 }}
                    >
                      Filter
                    </Button>
                    <Button
                      onClick={() => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            status: "",
                          };
                        });
                        clearFilters();
                      }}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Reset
                    </Button>
                  </div>
                ),
                render: function select(_, record) {
                  return (
                    <Select
                      defaultValue={record.user_status}
                      value={record.user_status}
                      style={{ minWidth: 100 }}
                      onChange={async (e) => {
                        setLoading(true);
                        const result = await updateUserStatus({
                          id: record.key,
                          status: e,
                        });
                        if (result) {
                          func({
                            page,
                            limit,
                            query: searchVal,
                            filter,
                            sorter: sortStates,
                          });
                        }
                      }}
                    >
                      <Select.Option value="in_progress">
                        In Progress
                      </Select.Option>
                      <Select.Option value="finished">Finished</Select.Option>
                      <Select.Option value="rejected">Rejected</Select.Option>
                    </Select>
                  );
                },
              },
              {
                title: "Adress",
                dataIndex: "full_address",
                width: 30,
                // eslint-disable-next-line react/display-name
                render: (text) => (
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "200px",
                    }}
                    onDoubleClick={() => handleCopy(text, "User Adress")}
                    title={text}
                  >
                    {text}
                  </div>
                ),
              },
              {
                title: "Licence Adress",
                dataIndex: "licence_address",
                width: 30,
                // eslint-disable-next-line react/display-name
                render: (text) => (
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "200px",
                    }}
                    onDoubleClick={() =>
                      handleCopy(text, "User Licence Adress")
                    }
                    title={text}
                  >
                    {text}
                  </div>
                ),
              },
              { title: "P. Code", dataIndex: "postal_code", width: 100 },
              {
                title: "Onboarding Completed At",
                dataIndex: "onboarding_completed_at",
                // eslint-disable-next-line react/display-name
                filterDropdown: ({
                  setSelectedKeys,
                  confirm,
                  clearFilters,
                }: any) => (
                  <div style={{ padding: 8 }}>
                    <DatePicker
                      onChange={(date, dateString) => {
                        handleDateChange(date, dateString);
                        setSelectedKeys(
                          date ? [moment(date).format("YYYY-MM-DD")] : []
                        );
                        confirm();
                      }}
                      value={
                        filter["onboarding_completed_at"]
                          ? moment(filter["onboarding_completed_at"])
                          : null
                      }
                      style={{ width: "100%" }}
                    />
                    <Button
                      onClick={() => {
                        setFilter((prev) => ({
                          ...prev,
                          onboarding_completed_at: null,
                        }));
                        clearFilters();
                        confirm();
                      }}
                      size="small"
                      style={{ width: 90, marginTop: 10 }}
                    >
                      Reset
                    </Button>
                  </div>
                ),
              },

              {
                title: "Create Quote",
                key: "create_quote",
                // eslint-disable-next-line react/display-name
                render: (record: any) => (
                  <ActionButton
                    recordKey={record.key}
                    loading={loading}
                    icon={<DiffOutlined />}
                    onClickButton={createQuote}
                  />
                ),
                fixed: "right",
                width: 80,
              },
              {
                title: "Update Veh. D.",
                key: "update_vehicle_data",
                // eslint-disable-next-line react/display-name
                render: (record: any) => (
                  <ActionButton
                    recordKey={record.key}
                    data={record}
                    loading={loading}
                    icon={<SyncOutlined />}
                    onClickButton={handleClickUpdateModal}
                  />
                ),
                fixed: "right",
                width: 80,
              },
              {
                title: "Delete User",
                key: "delete_user",
                // eslint-disable-next-line react/display-name
                render: (record: any) => (
                  <ActionButton
                    recordKey={record.key}
                    loading={loading}
                    icon={<DeleteOutlined />}
                    onClickButton={showDeleteConfirm}
                  />
                ),
                fixed: "right",
                width: 80,
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default UsersPage;
