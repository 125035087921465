export const inputRowStyle = {
  style: { marginTop: 10 },
};

export const actionButtonStyle = {
  style: { marginLeft: 10 },
};

export const saveIconStyle = {
  style: { color: "#1890ff" },
};

export const deleteIconStyle = {
  style: { color: "#ff4d4f" },
};
