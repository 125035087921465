import React, { FC } from "react";
import Routes from "./routes/Routes";
import Provider from "./contexts/Provider";

const App: FC = () => {
  return (
    <Provider>
      <Routes />
    </Provider>
  );
};

export default App;
