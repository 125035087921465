import React, { FC, useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Input,
  Button,
  Modal,
  Select,
  notification,
  DatePicker,
} from "antd";
import {
  bulkQuoteRequest,
  createQuoteRequest,
  getAllVersion,
  listDrivingScores,
  updateUserStatus,
} from "api/Api";
import SortableHeader from "components/table/sortableTableHeader/SortableTableHeader";
import {
  SaveOutlined,
  FundViewOutlined,
  DiffOutlined,
} from "@ant-design/icons";
import { Checkbox } from "antd";
import type { CheckboxProps } from "antd";
import ActionButton from "components/table/renderActionButton/RenderActionButton";
import { ColumnsType, ColumnType } from "antd/es/table";
import moment from "moment";
export interface IDrivingScorePageProps {}

const handleCopy = (text: string, type: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      notification.success({
        message: "Success",
        description: `${type} copied to clipboard.`,
        duration: 2,
      });
    })
    .catch((error) => {
      console.error("Failed to copy text:", error);
    });
};

const DrivingScorePage: FC<IDrivingScorePageProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [scores, setScores] = useState([]);
  const [allVersion, setallVersion] = useState([]);
  const [count, setCount] = useState<number>(0);
  const [searchVal, setSearchVal] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState(10);
  const [selectedScorecard, setSelectedScorecard] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sortStates, setSortStates] = useState({ field: null, order: null });
  const [filter, setFilter] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: any) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const fetchScores = useCallback(
    async ({
      page = 1,
      limit,
      query = "",
      sorter,
      filter = {},
      download = false,
    }) => {
      setLoading(true);
      try {
        const response = await listDrivingScores({
          page,
          limit,
          query,
          sorter,
          filter,
          download,
        });

        if (download) {
          window.open(response.url);
        } else {
          setScores(response.results);
          setCount(response.total_results);
        }
      } catch (error) {
        console.error("API error:", error);
      } finally {
        setLoading(false);
      }
    },
    [page, limit, sortStates, filter, listDrivingScores]
  );

  const getAllVersions = async () => {
    try {
      const data = await getAllVersion();
      setallVersion(data);
    } catch (error) {}
  };

  const createQuote = async (userId: string) => {
    setLoading(true);
    try {
      const result: any = await createQuoteRequest({ userId });
      if (result?.status === 200) {
        notification.success({
          message: "Success",
          description: "Quote created successfully.",
          duration: 2,
        });
      } else {
        notification.error({
          message: "Error",
          description: "Error while creating quote.",
          duration: 2,
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error while creating quote.",
        duration: 2,
      });
    } finally {
      fetchScores({
        page,
        limit,
        query: searchVal,
        filter,
        sorter: sortStates,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllVersions();
  }, []);

  useEffect(() => {
    fetchScores({
      page,
      limit,
      query: searchVal,
      sorter: sortStates,
      filter,
    });
  }, [page, limit, sortStates, searchVal, filter]);

  const createBulkQuotes = async () => {
    setLoading(true);
    try {
      await bulkQuoteRequest({ userIds: selectedRowKeys });
      notification.success({
        message: "Success",
        description: "Bulk quotes completed successfully.",
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occurred while creating the creating quotes.",
      });
    } finally {
      setLoading(false);
      fetchScores({
        page,
        limit,
        query: searchVal,
        filter,
        sorter: sortStates,
      });
    }
  };

  const handleHeaderClick = (columnName: string) => {
    let newOrder;
    if (sortStates.field === columnName) {
      if (sortStates.order === "ascend") {
        newOrder = "descend";
      } else if (sortStates.order === "descend") {
        newOrder = null;
      } else {
        newOrder = "ascend";
      }
    } else {
      newOrder = "ascend";
    }

    setSortStates({ field: newOrder ? columnName : null, order: newOrder });
  };

  const ScorecardModal = ({ scorecard, visible, onClose }: any) => (
    <Modal
      title="Scorecard Details"
      visible={visible}
      onOk={onClose}
      onCancel={onClose}
    >
      <div>
        {scorecard &&
          Object.entries(scorecard).map(([key, value]) => (
            <p key={key}>
              <strong>{key}:</strong> {value}
            </p>
          ))}
      </div>
    </Modal>
  );
  ScorecardModal.displayName = "ScorecardModal";

  const getSortIndicator = (columnName: string) => {
    if (sortStates.field === columnName) {
      if (sortStates.order === "ascend") {
        return "↑";
      } else if (sortStates.order === "descend") {
        return "↓";
      }
    }
    return "-";
  };

  const getSortDescription = (columnName: string) => {
    if (sortStates.field === columnName) {
      switch (sortStates.order) {
        case "ascend":
          return "Sort descending";
        case "descend":
          return "Remove sorting";
        default:
          return "Sort ascending";
      }
    } else {
      return "Sort ascending";
    }
  };

  const createSortableColumn = (
    title: string,
    dataIndex: string,
    width?: number,
    columnFullname?: string,
    align: "left" | "right" | "center" = "center"
  ): ColumnType<any> => ({
    title: (
      <SortableHeader
        title={title}
        columnFullname={columnFullname}
        dataIndex={dataIndex}
        handleHeaderClick={handleHeaderClick}
        getSortIndicator={getSortIndicator}
        getSortDescription={getSortDescription}
      />
    ),
    dataIndex,
    width,
    align,
    render: (text: string) => {
      if (
        dataIndex === "test_drive_started_at" ||
        dataIndex === "checked_at" ||
        dataIndex === "last_quote_created_at" ||
        dataIndex === "shutdown_at"
      ) {
        return text
          ? new Date(text).toLocaleString("en-GB", { timeZone: "UTC" })
          : text;
      }

      const numberValue = parseFloat(text);
      if (!isNaN(numberValue)) {
        //! If there is only Zero after the comma, show only the integer part
        if (numberValue % 1 === 0) {
          return Math.round(numberValue).toString();
        }
        return numberValue.toFixed(3);
      }

      return text;
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: "User ID",
      dataIndex: "user_id",
      // eslint-disable-next-line react/display-name
      render: (text: string) => (
        <div
          style={{
            wordWrap: "break-word",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "140px",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
          onDoubleClick={() => handleCopy(text, "User ID")}
          title={text}
        >
          {text}
        </div>
      ),
      fixed: true,
      width: 200,
    },
    {
      title: "E-mail",
      dataIndex: "email",
      fixed: true,
      width: 232,
      // eslint-disable-next-line react/display-name
      render: (text: string) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "200px",
            cursor: "pointer",
          }}
          title={text}
          onDoubleClick={() => handleCopy(text, "Email")}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Age",
      dataIndex: "user_age",
      // eslint-disable-next-line react/display-name
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Select
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e ? [e] : []);
              confirm();
              setFilter((prev) => {
                return {
                  ...prev,
                  userAge: e,
                };
              });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            {Array.from({ length: 64 }, (_, i) => i + 17).map((age) => (
              <Select.Option key={age} value={age}>
                {age}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              setFilter((prev) => {
                return {
                  ...prev,
                  userAge: "",
                };
              });
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        { text: "In Progress", value: "in_progress" },
        { text: "Finished", value: "finished" },
        { text: "Rejected", value: "rejected" },
      ],
      width: 160,
      // eslint-disable-next-line react/display-name, react/prop-types
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Select
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e ? [e] : []);
              confirm();
              setFilter((prev) => {
                return {
                  ...prev,
                  status: e,
                };
              });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            <Select.Option value="in_progress">In Progress</Select.Option>
            <Select.Option value="finished">Finished</Select.Option>
            <Select.Option value="rejected">Rejected</Select.Option>
          </Select>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              setFilter((prev) => {
                return {
                  ...prev,
                  status: "",
                };
              });
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      render: function select(_: any, record: any) {
        return (
          <Select
            defaultValue={record.status}
            value={record.status}
            style={{ minWidth: 100 }}
            onChange={async (e) => {
              try {
                setLoading(true);

                const result = await updateUserStatus({
                  id: record.user_id,
                  status: e,
                });
                if (result) {
                  notification.success({
                    message: "Success",
                    description: `User status updated successfully!`,
                    duration: 2,
                  });
                  fetchScores({
                    page,
                    limit,
                    query: searchVal,
                    filter,
                    sorter: sortStates,
                  });
                }
              } catch (error) {
                notification.success({
                  message: "Error",
                  description: `Failed to user status update!`,
                  duration: 2,
                });
                console.error("Failed to user status update:", error);
              }
            }}
          >
            <Select.Option value="in_progress">In Progress</Select.Option>
            <Select.Option value="finished">Finished</Select.Option>
            <Select.Option value="rejected">Rejected</Select.Option>
          </Select>
        );
      },
    },
    { title: "P. Code", dataIndex: "postal_code", align: "center" },
    createSortableColumn(
      "TD Started At",
      "test_drive_started_at",
      200,
      "Test Drive Started At",
      "left"
    ),
    createSortableColumn(
      "Shutdown At",
      "shutdown_at",
      200,
      "Shutdown At",
      "left"
    ),
    {
      title: "TD Date",
      dataIndex: "test_drive_date",
      // eslint-disable-next-line react/display-name
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <DatePicker
            onChange={(date, dateString) => {
              handleDateChange(date, dateString);
              setSelectedKeys(date ? [moment(date).format("YYYY-MM-DD")] : []);
              confirm();
            }}
            value={
              filter["test_drive_date"]
                ? moment(filter["test_drive_date"])
                : null
            }
            style={{ width: "100%" }}
          />
          <Button
            onClick={() => {
              setFilter((prev) => ({
                ...prev,
                test_drive_date: null,
              }));
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90, marginTop: 10 }}
          >
            Reset
          </Button>
        </div>
      ),
    },
    {
      title: "TD Time",
      dataIndex: "test_drive_time",
    },
    {
      title: "No Trip EF",
      dataIndex: "no_trip_email_flag",
      align: "center",
    },
    {
      title: "Device Type",
      dataIndex: "system_type",
      width: 150,
      align: "center",

      // eslint-disable-next-line react/display-name
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Select
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e ? [e] : []);
              confirm();
              setFilter((prev) => {
                return {
                  ...prev,
                  systemType: e,
                };
              });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            <Select.Option value="iOS">IOS</Select.Option>
            <Select.Option value="Android">Android</Select.Option>
          </Select>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              setFilter((prev) => {
                return {
                  ...prev,
                  systemType: "",
                };
              });
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
    },
    {
      title: (
        <SortableHeader
          title="App Version"
          dataIndex="latest_app_version"
          handleHeaderClick={handleHeaderClick}
          getSortIndicator={getSortIndicator}
          getSortDescription={getSortDescription}
        />
      ),
      dataIndex: "latest_app_version",
      width: 150,
      align: "center",

      // eslint-disable-next-line react/display-name
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Select
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e ? [e] : []);
              confirm();
              setFilter((prev) => {
                return {
                  ...prev,
                  appVersion: e,
                };
              });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            {allVersion.map((version) => (
              <Select.Option key={version} value={version}>
                {version}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              setFilter((prev) => {
                return {
                  ...prev,
                  appVersion: "",
                };
              });
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
    },
    {
      title: (
        <SortableHeader
          title="TD Provider"
          columnFullname="Test Drive Provider"
          dataIndex="test_drive_provider"
          handleHeaderClick={handleHeaderClick}
          getSortIndicator={getSortIndicator}
          getSortDescription={getSortDescription}
        />
      ),
      dataIndex: "test_drive_provider",
      width: 150,
      align: "center",
      // eslint-disable-next-line react/display-name
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Select
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e ? [e] : []);
              confirm();
              setFilter((prev) => {
                return {
                  ...prev,
                  testDriveProvider: e,
                };
              });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            <Select.Option value="amodo">Amodo</Select.Option>
            <Select.Option value="move">Move</Select.Option>
          </Select>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              setFilter((prev) => {
                return {
                  ...prev,
                  testDriveProvider: "",
                };
              });
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
    },
    {
      title: "Renewal Month",
      dataIndex: "renewal_month",
      width: 150,
      align: "center",
      // eslint-disable-next-line react/display-name
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Select
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e ? [e] : []);
              confirm();
              setFilter((prev) => {
                return {
                  ...prev,
                  renewal_month: e,
                };
              });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            <Select.Option value="January">January</Select.Option>
            <Select.Option value="February">February</Select.Option>
            <Select.Option value="March">March</Select.Option>
            <Select.Option value="April">April</Select.Option>
            <Select.Option value="May">May</Select.Option>
            <Select.Option value="June">June</Select.Option>
            <Select.Option value="July">July</Select.Option>
            <Select.Option value="August">August</Select.Option>
            <Select.Option value="September">September</Select.Option>
            <Select.Option value="October">October</Select.Option>
            <Select.Option value="November">November</Select.Option>
            <Select.Option value="December">December</Select.Option>
          </Select>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              setFilter((prev) => {
                return {
                  ...prev,
                  renewal_month: "",
                };
              });
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
    },
    {
      title: "Pay Frequecy",
      dataIndex: "pay_frequency",
      width: 150,
      align: "center",
      // eslint-disable-next-line react/display-name
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Select
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e ? [e] : []);
              confirm();
              setFilter((prev) => {
                return {
                  ...prev,
                  pay_frequency: e,
                };
              });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            <Select.Option value="Monthly">Monthly</Select.Option>
            <Select.Option value="Yearly">Yearly</Select.Option>
          </Select>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              setFilter((prev) => {
                return {
                  ...prev,
                  pay_frequency: "",
                };
              });
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
    },
    {
      title: "Current Price",
      dataIndex: "current_price",
      width: 120,
      align: "center",
    },
    {
      title: "Industry",
      dataIndex: "industry",
      width: 150,
      align: "center",
      // eslint-disable-next-line react/display-name
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
              setFilter((prev) => {
                return {
                  ...prev,
                  industry: selectedKeys[0],
                };
              });
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              setFilter((prev) => {
                return {
                  ...prev,
                  industry: "",
                };
              });
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
    },
    {
      title: "Occupation",
      dataIndex: "occupation",
      width: 150,
      align: "center",
      // eslint-disable-next-line react/display-name
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
              setFilter((prev) => {
                return {
                  ...prev,
                  occupation: selectedKeys[0],
                };
              });
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              setFilter((prev) => {
                return {
                  ...prev,
                  occupation: "",
                };
              });
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
    },

    // createSortableColumn("Score", "combined_score", 85, "Combined Score"),
    {
      title: (
        <SortableHeader
          title="Score"
          dataIndex="combined_score"
          handleHeaderClick={handleHeaderClick}
          getSortIndicator={getSortIndicator}
          getSortDescription={getSortDescription}
        />
      ),
      dataIndex: "combined_score",
      width: 110,
      align: "center",

      // eslint-disable-next-line react/display-name
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Select
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e !== undefined ? [e] : []);
              confirm();
              setFilter((prev) => {
                return {
                  ...prev,
                  score: e,
                };
              });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            {Array.from({ length: 11 }, (_, i) => i).map((score, index) => (
              <Select.Option key={index} value={score}>
                {score}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              setFilter((prev) => {
                return {
                  ...prev,
                  score: "",
                };
              });
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
    },
    createSortableColumn(
      "Score Fac.",
      "combined_factor",
      120,
      "Combined Score Factor"
    ),
    {
      title: (
        <SortableHeader
          title="Trips"
          columnFullname="Number Of Trips"
          dataIndex="number_of_trips"
          handleHeaderClick={handleHeaderClick}
          getSortIndicator={getSortIndicator}
          getSortDescription={getSortDescription}
        />
      ),
      dataIndex: "number_of_trips",
      width: 100,
      align: "center",
      // eslint-disable-next-line react/display-name
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Number Of Trips`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              setFilter((prev) => {
                return {
                  ...prev,
                  numberOfTrips: e.target.value,
                };
              });
            }}
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              setFilter((prev) => {
                return {
                  ...prev,
                  numberOfTrips: null,
                };
              });
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
    },
    createSortableColumn("Total Mileage", "total_mileage", 150),
    {
      title: "Scorecard",
      dataIndex: "scorecard",
      width: 100,
      align: "center",
      // eslint-disable-next-line react/display-name
      render: (_: any, record: any) => (
        <Button
          onClick={() => {
            setSelectedScorecard(record.scorecard);
            setIsModalVisible(true);
          }}
        >
          <FundViewOutlined />
        </Button>
      ),
    },
    createSortableColumn("Speeding", "speeding", 120),
    createSortableColumn(
      "Speeding Fac.",
      "speeding_factor",
      145,
      "Speeding Factor"
    ),
    createSortableColumn("Late Drv", "late_driving", 110, "Late Driving"),
    createSortableColumn(
      "Late Drv Fac.",
      "late_driving_factor",
      135,
      "Late Driving Factor"
    ),
    createSortableColumn("Braking", "braking", 120),
    createSortableColumn(
      "Braking Fac.",
      "braking_factor",
      135,
      "Braking Factor"
    ),
    createSortableColumn("Dist 20", "distance_20", 90, "Distance 20"),
    createSortableColumn(
      "Dist 20 Fac.",
      "distance_20_factor",
      135,
      "Distance 20 Factor"
    ),
    createSortableColumn("Dist 70", "distance_70", 90, "Distance 70"),
    createSortableColumn(
      "Dist 70 Fac.",
      "distance_70_factor",
      135,
      "Distance 70 Factor"
    ),
    createSortableColumn("Average M.", "average_miles", 140, "Avarage Miles"),
    createSortableColumn(
      "Avarage M. Fac.",
      "average_miles_factor",
      155,
      "Avarage Miles Factor"
    ),
    createSortableColumn("Idle T.", "idle_time", 100, "Idle Time"),
    createSortableColumn(
      "Idle T. Fac.",
      "idle_time_factor",
      135,
      "Idle Time Factor"
    ),
    createSortableColumn(
      "Multi Dec.",
      "multi_deceleration",
      120,
      "Multi Deceleration"
    ),
    createSortableColumn(
      "Multi Dec. Fac.",
      "multi_deceleration_factor",
      145,
      "Multi Deceleration Factor"
    ),
    createSortableColumn(
      "SOM",
      "speeding_on_motorway",
      100,
      "Speeding On Motorway"
    ),
    createSortableColumn(
      "SOM Fac.",
      "speeding_on_motorway_factor",
      135,
      "Speeding On Motorway Factor"
    ),
    createSortableColumn("FT", "fiddling_time", 100, "Fiddling Time"),
    createSortableColumn(
      "FT Fac.",
      "fiddling_time_factor",
      135,
      "Fiddling Time Factor"
    ),
    createSortableColumn("Checked At", "checked_at", 250, "left"),
    createSortableColumn("Last Quote", "last_quote_created_at", 250, "left"),
    {
      title: "Create Quote",
      key: "create_quote",
      // eslint-disable-next-line react/display-name
      render: (record: any) => (
        <ActionButton
          recordKey={record.user_id}
          loading={loading}
          icon={<DiffOutlined />}
          onClickButton={createQuote}
        />
      ),
      width: 80,
      fixed: "right",
      align: "center",
    },
  ];

  const handleDateChange = (date: any, dateString: any) => {
    setFilter((prev) => ({
      ...prev,
      test_drive_date: dateString,
    }));
  };

  const onChangeHighScores: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      setFilter((prev) => ({
        ...prev,
        highScores: true,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        highScores: false,
      }));
    }
  };

  const onChangeValidScores: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      setFilter((prev) => ({
        ...prev,
        validScores: true,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        validScores: false,
      }));
    }
  };
  return (
    <>
      <ScorecardModal
        scorecard={selectedScorecard}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      />
      <Row>
        <Col span={24}>
          <Row style={{ marginBottom: 10 }}>
            <Col span={6}>
              <Input.Search
                placeholder="Search"
                onSearch={(val) => {
                  if (val) {
                    setPage(1);
                  }
                  setSearchVal(val);
                }}
              />
            </Col>
            <Col
              span={18}
              style={{
                textAlign: "right",
                display: "flex",
                justifyContent: "flex-end",
                gap: 20,
              }}
            >
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={createBulkQuotes}
                  disabled={loading || selectedRowKeys.length === 0}
                >
                  Create Multi Quotes
                </Button>
              )}
              <Button
                disabled={loading}
                style={{ marginRight: 8 }}
                onClick={async () => {
                  fetchScores({
                    page,
                    limit,
                    query: searchVal,
                    sorter: sortStates,
                    filter,
                    download: true,
                  });
                }}
              >
                <SaveOutlined />
              </Button>
            </Col>
          </Row>

          <Row style={{ marginBottom: 10 }}>
            <Checkbox
              onChange={onChangeHighScores}
            >{`High Scores (Quote Missing)`}</Checkbox>
            <Checkbox
              onChange={onChangeValidScores}
            >{`Valid Scores (Quote Missing)`}</Checkbox>
          </Row>
          <Table
            rowSelection={rowSelection}
            loading={loading}
            rowKey="user_id"
            pagination={{
              current: page,
              pageSize: limit,
              total: count,
              onChange: (newPage, newPageSize) => {
                setPage(newPage);
                setLimit(newPageSize);
              },
              onShowSizeChange: (newPage, newPageSize) => {
                setPage(newPage);
                setLimit(newPageSize);
              },
            }}
            dataSource={scores}
            columns={columns}
            scroll={{ x: "max-content" }}
            tableLayout="fixed"
          />
        </Col>
      </Row>
    </>
  );
};

export default DrivingScorePage;
