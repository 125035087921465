import styled from "styled-components";
import { Button, Row, Layout } from "antd";
const { Header, Sider, Content } = Layout;

const collapsedSideWidth = 120;
const expandedSideWidth = 200;
const headerHeight = 64;

export const Container = styled.div`
  width: 100%;
`;

export const RightAlignedButton = styled(Button)`
  margin-left: auto;
`;

export const FullHeightRow = styled(Row)`
  height: 100%;
`;

export const FullHeightLayout = styled(Layout)`
  height: 100vh;
  overflow: hidden;
`;

export const CustomSider = styled(Sider)`
  background: white;
  max-width: unset !important;
  min-width: unset !important;
  flex: none !important;
  width: ${({ collapsed }) =>
    collapsed ? collapsedSideWidth : expandedSideWidth}px !important;
  .ant-menu-item:first-of-type {
    margin-top: 0;
  }
  position: fixed;
  height: 100%;
`;

export const HamburgerButton = styled(Button)`
  position: absolute;
  color: #000;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  padding: 0;
  left: -40px !important;
  background: #f6faff;
  :hover,
  :focus {
    color: #e64b5f;
    background: #f6faff;
  }
`;

export const LogoPlaceHolder = styled.div`
  height: 32px;
  margin: 16px 48px;
  background: #e64b5f;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const CustomHeader = styled(Header)<{ collapsed: boolean }>`
  background: white;
  position: fixed;
  width: calc(
    100% -
      ${({ collapsed }) =>
        collapsed ? collapsedSideWidth : expandedSideWidth}px
  );
  left: ${({ collapsed }) =>
    collapsed ? collapsedSideWidth : expandedSideWidth}px !important;
  z-index: 2;
  transition: all 0.2s;
`;

export const ContentWithPadding = styled(Content)<{ collapsed: boolean }>`
  left: ${({ collapsed }) =>
    collapsed ? collapsedSideWidth : expandedSideWidth}px;
  right: 0;
  position: fixed;
  top: ${headerHeight}px;
  bottom: 0;
  overflow: auto;
  box-shadow: inset 0px 11px 8px -10px #ccc, inset 0px -11px 8px -10px #ccc;
  padding: 20px;
`;
