import { InitialState, StateType } from "./State";
import { Action, ActionTypes } from "./Types";

interface ReturnType {
  app: StateType;
}

export const reducer = (
  state: StateType = InitialState,
  action: Action
): ReturnType | StateType => {
  switch (action.type) {
    case ActionTypes.ALERT_OPEN:
      return {
        ...state,
        app: { ...action.payload },
      };
    case ActionTypes.ALERT_CLOSE:
      const StateTypeWithAppProperty = state as StateType & { app: StateType };
      return {
        ...StateTypeWithAppProperty,
        app: { ...StateTypeWithAppProperty.app, isAlertVisible: false },
      };
    default:
      return { ...state };
  }
};
