import React, { FC, useState } from "react";
import { layout, tailLayout } from "./NCDStyles";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Alert,
  Card,
  Row,
  Col,
  Select,
  message,
} from "antd";
import { useFormik, FormikValues } from "formik";
import { submitNCD } from "api/Api";
import * as Yup from "yup";
import moment from "moment";

interface IRNCDPageProps {}

const NCDSchema = Yup.object().shape({
  DLN: Yup.string(),
  DateOfBirth: Yup.string().required("Required."),
  AddressLine1: Yup.string().when("DLN", {
    is: (DLN: any) => DLN && DLN.length === 0,
    then: Yup.string().required("Required"),
  }),
  AddressLine2: Yup.string(),
  AddressLine3: Yup.string(),
  AddressLine4: Yup.string(),
  EffectiveDate: Yup.string().required("Required."),
  FamilyName: Yup.string().required("Required."),
  ForenameInitial: Yup.string().required("Required."),
  GBDriverFlag: Yup.string().when("DLN", {
    is: (DLN: any) => DLN && DLN.length > 0,
    then: Yup.string().required("Required"),
  }),
  Gender: Yup.string().required("Required."),
  NCDType: Yup.string().required("Required."),
  NCDValue: Yup.number().required("Required."),
  Postcode: Yup.string().required("Required."),
  ReasonCode: Yup.number().required("Required."),
  VehicleType: Yup.string().required("Required."),
  VRM: Yup.string().when("VehicleType", {
    is: (VehicleType: any) => VehicleType !== "T",
    then: Yup.string().required("Required"),
  }),
});

const NCDPage: FC<IRNCDPageProps> = () => {
  const [error, setError] = useState<string>("");
  const formRef = React.createRef<any>();

  const submit = async (v: FormikValues) => {
    try {
      setError("");
      await submitNCD({
        DLN: v.DLN,
        DateOfBirth: v.DateOfBirth,
        AddressLine1: v.AddressLine1,
        AddressLine2: v.AddressLine2,
        AddressLine3: v.AddressLine3,
        AddressLine4: v.AddressLine4,
        EffectiveDate: v.EffectiveDate,
        FamilyName: v.FamilyName,
        ForenameInitial: v.ForenameInitial,
        GBDriverFlag: v.GBDriverFlag,
        Gender: v.Gender,
        NCDType: v.NCDType,
        NCDValue: v.NCDValue,
        Postcode: v.Postcode,
        ReasonCode: v.ReasonCode,
        VehicleType: v.VehicleType,
        VRM: v.VRM,
      });
      message.success("Successfully updated.");
    } catch (e) {
      setError("Error occured on submit.");
    }
  };

  const formik = useFormik({
    initialValues: {
      RecordId: "",
      DLN: "",
      DateOfBirth: undefined,
      AddressLine1: "",
      AddressLine2: "",
      AddressLine3: "",
      AddressLine4: "",
      EffectiveDate: undefined,
      FamilyName: "",
      ForenameInitial: "",
      GBDriverFlag: undefined,
      Gender: undefined,
      NCDType: undefined,
      NCDValue: "",
      Postcode: "",
      ReasonCode: undefined,
      TransactionId: "",
      VehicleType: undefined,
      VRM: "",
    },
    validationSchema: NCDSchema,
    onSubmit: submit,
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = formik;

  return (
    <Row>
      <Col span={16} offset={4}>
        <Card>
          <Form
            {...layout}
            name="NCD Form"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            ref={formRef}
          >
            <h1 style={{ marginBottom: 20 }}>NCD Form</h1>
            <Row>
              <Col span={6}>
                <Form.Item
                  validateStatus={
                    errors.DLN && touched.DLN ? "error" : "success"
                  }
                  help={errors.DLN && touched.DLN ? errors.DLN : null}
                >
                  <Input
                    placeholder="DLN"
                    name="DLN"
                    value={values.DLN}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  validateStatus={
                    errors.DateOfBirth && touched.DateOfBirth
                      ? "error"
                      : "success"
                  }
                  help={
                    errors.DateOfBirth && touched.DateOfBirth
                      ? errors.DateOfBirth
                      : null
                  }
                >
                  <DatePicker
                    format="yyyy-MM-DD"
                    onChange={(e) => {
                      setFieldValue("DateOfBirth", moment(e, "yyyy-MM-DD"));
                    }}
                    placeholder="Date Of Birth"
                    name="DateOfBirth"
                    value={values.DateOfBirth}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  validateStatus={
                    errors.AddressLine1 && touched.AddressLine1
                      ? "error"
                      : "success"
                  }
                  help={
                    errors.AddressLine1 && touched.AddressLine1
                      ? errors.AddressLine1
                      : null
                  }
                >
                  <Input
                    placeholder="Driver address 1"
                    name="AddressLine1"
                    value={values.AddressLine1}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  validateStatus={
                    errors.AddressLine2 && touched.AddressLine2
                      ? "error"
                      : "success"
                  }
                  help={
                    errors.AddressLine2 && touched.AddressLine2
                      ? errors.AddressLine2
                      : null
                  }
                >
                  <Input
                    placeholder="Driver address 2"
                    name="AddressLine2"
                    value={values.AddressLine2}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  validateStatus={
                    errors.AddressLine3 && touched.AddressLine3
                      ? "error"
                      : "success"
                  }
                  help={
                    errors.AddressLine3 && touched.AddressLine3
                      ? errors.AddressLine3
                      : null
                  }
                >
                  <Input
                    placeholder="Driver address 3"
                    name="AddressLine3"
                    value={values.AddressLine3}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>{" "}
              <Col span={6}>
                <Form.Item
                  validateStatus={
                    errors.AddressLine4 && touched.AddressLine4
                      ? "error"
                      : "success"
                  }
                  help={
                    errors.AddressLine4 && touched.AddressLine4
                      ? errors.AddressLine4
                      : null
                  }
                >
                  <Input
                    placeholder="Driver address 4"
                    name="AddressLine4"
                    value={values.AddressLine4}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  validateStatus={
                    errors.EffectiveDate && touched.EffectiveDate
                      ? "error"
                      : "success"
                  }
                  help={
                    errors.EffectiveDate && touched.EffectiveDate
                      ? errors.EffectiveDate
                      : null
                  }
                >
                  <DatePicker
                    format="yyyy-MM-DD"
                    onChange={(e) => {
                      setFieldValue("EffectiveDate", moment(e, "yyyy-MM-DD"));
                    }}
                    placeholder="Effective Date"
                    name="EffectiveDate"
                    value={values.EffectiveDate}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  validateStatus={
                    errors.FamilyName && touched.FamilyName
                      ? "error"
                      : "success"
                  }
                  help={
                    errors.FamilyName && touched.FamilyName
                      ? errors.FamilyName
                      : null
                  }
                >
                  <Input
                    placeholder="Family Name"
                    name="FamilyName"
                    value={values.FamilyName}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  validateStatus={
                    errors.ForenameInitial && touched.ForenameInitial
                      ? "error"
                      : "success"
                  }
                  help={
                    errors.ForenameInitial && touched.ForenameInitial
                      ? errors.ForenameInitial
                      : null
                  }
                >
                  <Input
                    placeholder="Forename Initial"
                    name="ForenameInitial"
                    value={values.ForenameInitial}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="GBDriverFlag"
                  validateStatus={
                    errors.GBDriverFlag && touched.GBDriverFlag
                      ? "error"
                      : "success"
                  }
                  help={
                    errors.GBDriverFlag && touched.GBDriverFlag
                      ? errors.GBDriverFlag
                      : null
                  }
                >
                  <Select
                    placeholder="GB Driver Flag"
                    defaultValue={values.GBDriverFlag}
                    style={{ width: "%100" }}
                    onChange={(e) => {
                      setFieldValue("GBDriverFlag", e);
                    }}
                  >
                    <Select.Option value="Y">Y</Select.Option>
                    <Select.Option value="N">N</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="Gender"
                  validateStatus={
                    errors.Gender && touched.Gender ? "error" : "success"
                  }
                  help={errors.Gender && touched.Gender ? errors.Gender : null}
                >
                  <Select
                    placeholder="Gender"
                    defaultValue={values.Gender}
                    style={{ width: "%100" }}
                    onChange={(e) => {
                      setFieldValue("Gender", e);
                    }}
                  >
                    <Select.Option value="M">M</Select.Option>
                    <Select.Option value="F">F</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="NCDType"
                  validateStatus={
                    errors.NCDType && touched.NCDType ? "error" : "success"
                  }
                  help={
                    errors.NCDType && touched.NCDType ? errors.NCDType : null
                  }
                >
                  <Select
                    placeholder="NCD Type"
                    defaultValue={values.NCDType}
                    style={{ width: "%100" }}
                    onChange={(e) => {
                      setFieldValue("NCDType", e);
                    }}
                  >
                    <Select.Option value="S">Standard</Select.Option>
                    <Select.Option value="P">Protected</Select.Option>
                    <Select.Option value="G">Guaranteed</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  validateStatus={
                    errors.NCDValue && touched.NCDValue ? "error" : "success"
                  }
                  help={
                    errors.NCDValue && touched.NCDValue ? errors.NCDValue : null
                  }
                >
                  <Input
                    placeholder="NCD Value"
                    name="NCDValue"
                    value={values.NCDValue}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  validateStatus={
                    errors.Postcode && touched.Postcode ? "error" : "success"
                  }
                  help={
                    errors.Postcode && touched.Postcode ? errors.Postcode : null
                  }
                >
                  <Input
                    placeholder="Postcode"
                    name="Postcode"
                    value={values.Postcode}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="ReasonCode"
                  validateStatus={
                    errors.ReasonCode && touched.ReasonCode
                      ? "error"
                      : "success"
                  }
                  help={
                    errors.ReasonCode && touched.ReasonCode
                      ? errors.ReasonCode
                      : null
                  }
                >
                  <Select
                    placeholder="Reason Code"
                    defaultValue={values.ReasonCode}
                    style={{ width: "%100" }}
                    onChange={(e) => {
                      setFieldValue("ReasonCode", e);
                    }}
                  >
                    <Select.Option value={"1"}>Original deposit</Select.Option>
                    <Select.Option value={"2"}>
                      Updated deposit record
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="VehicleType"
                  validateStatus={
                    errors.VehicleType && touched.VehicleType
                      ? "error"
                      : "success"
                  }
                  help={
                    errors.VehicleType && touched.VehicleType
                      ? errors.VehicleType
                      : null
                  }
                >
                  <Select
                    placeholder="Vehicle Type"
                    defaultValue={values.VehicleType}
                    style={{ width: "%100" }}
                    onChange={(e) => {
                      setFieldValue("VehicleType", e);
                    }}
                  >
                    <Select.Option value={"C"}>Private Car</Select.Option>
                    <Select.Option value={"M"}>Motorcycle</Select.Option>
                    <Select.Option value={"L"}>LGV</Select.Option>
                    <Select.Option value={"H"}>HGV</Select.Option>
                    <Select.Option value={"T"}>Motor Trade</Select.Option>
                    <Select.Option value={"R"}>Private Hire</Select.Option>
                    <Select.Option value={"U"}>Public Hire</Select.Option>
                    <Select.Option value={"O"}>Other</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  validateStatus={
                    errors.VRM && touched.VRM ? "error" : "success"
                  }
                  help={errors.VRM && touched.VRM ? errors.VRM : null}
                >
                  <Input
                    placeholder="VRM"
                    name="VRM"
                    value={values.VRM}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            {error ? (
              <Form.Item {...tailLayout}>
                <Alert type="error" message={error} />
              </Form.Item>
            ) : null}
            <Row>
              <Col span={4} offset={10}>
                <Form.Item
                  wrapperCol={{ offset: 7, span: 24 }}
                  style={{ margin: "auto" }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default NCDPage;
