import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
  width: 80%;
  min-width: 320px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const layout = {
  wrapperCol: { offset: 1, span: 22 },
};

export const tailLayout = {
  wrapperCol: { offset: 1, span: 22 },
};

export const submitContainer = {
  style: { marginBottom: 50 },
};
