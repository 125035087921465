import { InitialState, StateType } from "./State";
import { Action, ActionTypes } from "./Types";

interface ReturnType {
  auth: StateType;
}

export const reducer = (
  state: StateType = InitialState,
  action: Action
): ReturnType => {
  switch (action.type) {
    case ActionTypes.AUTH_SUCCESS:
      return {
        ...state,
        auth: { ...action.payload },
      };
    default:
      return { auth: { ...state } };
  }
};
