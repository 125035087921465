export enum ActionTypes {
  AUTH_LOADING = "AUTH_LOADING",
  AUTH_SUCCESS = "AUTH_SUCCESS",
  AUTH_ERROR = "AUTH_ERROR",
}

export type Action = LoadingAction | SuccessAction | ErrorAction;

export interface LoadingAction {
  type: "AUTH_LOADING";
}

export interface SuccessAction {
  type: "AUTH_SUCCESS";
  payload: { isLoggedIn: boolean; authToken: string | null };
}

export interface ErrorAction {
  type: "AUTH_ERROR";
}
