import React, { FC, useEffect, useState } from "react";
import { Row, Col, Card, Divider, Input, Button, Spin, message } from "antd";
const { TextArea } = Input;
import { AmodoUser } from "../../types/AmodoUser";
import { ApiUser } from "../../types/ApiUser";
import { Answer } from "../../types/Answer";
import { amodoDataDetail, updateAmodoData } from "api/Api";
import { SaveOutlined } from "@ant-design/icons";

import { Tabs } from "antd";
import { AppleOutlined, AndroidOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;

import {
  inputRowStyle,
  titleStyle,
  saveIconStyle,
  actionButtonStyle,
} from "./amodoDataDetailStyle";
export interface IAmodoDataDetailPageProps {
  email: string;
}

const AmodoDataDetailPage: FC<IAmodoDataDetailPageProps> = ({
  email,
}: IAmodoDataDetailPageProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [amodoUser, setAmodoUser] = useState<AmodoUser>(Object);
  const [apiUsers, setApiUser] = useState<Array<ApiUser>>(Object);
  const [answers, setAnswers] = useState<Array<Answer>>(Array);
  const [quotePrice, setQuotePrice] = useState<number>();
  const [notes, setNotes] = useState<string>("");

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      try {
        const result = await amodoDataDetail({ email });
        setAmodoUser(result.data.details.amodo);
        setApiUser(result.data.details.api);
        setAnswers(result.data.details.answers);
        setQuotePrice(result.data.details.amodo.quote_price);
        setNotes(result.data.details.amodo.notes);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };
    func();
  }, []);

  const update = async () => {
    try {
      setSaveLoading(true);
      await updateAmodoData({ email, quotePrice, notes });
      setSaveLoading(false);
      message.success("Successfully updated.");
    } catch {
      setSaveLoading(false);
      message.error("Error on update.");
    }
  };

  return (
    <Row>
      <Col span={16} offset={4}>
        <Card>
          <Col span={24}>
            Quote Price
            <Row>
              <Col span={6}>
                <Input
                  value={quotePrice}
                  onChange={(e) => setQuotePrice(Number(e.target.value))}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            Notes
            <Row>
              <Col span={12}>
                <TextArea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Button
              loading={saveLoading}
              {...actionButtonStyle}
              onClick={update}
            >
              <SaveOutlined {...saveIconStyle} />
            </Button>
          </Col>
        </Card>
        <Card key={amodoUser.id}>
          {loading && <Spin />}
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              User ID
            </Col>
            <Col span={17}>{amodoUser.user_id}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Username
            </Col>
            <Col span={17}>{amodoUser.username}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              First Name
            </Col>
            <Col span={17}>{amodoUser.first_name}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Last Name
            </Col>
            <Col span={17}>{amodoUser.last_name}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              E-maik
            </Col>
            <Col span={17}>{amodoUser.email_address}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Phone Type
            </Col>
            <Col span={17}>{amodoUser.phone_type}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Mobile Login Count
            </Col>
            <Col span={17}>{amodoUser.mobile_login_count}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Last Notification
            </Col>
            <Col span={17}>{amodoUser.last_push_notification}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Birth
            </Col>
            <Col span={17}>{amodoUser.birth}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Full Address
            </Col>
            <Col span={17}>{amodoUser.full_address}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Postal Code
            </Col>
            <Col span={17}>{amodoUser.postal_code}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              License Type
            </Col>
            <Col span={17}>{amodoUser.licence_type}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Occupation
            </Col>
            <Col span={17}>{amodoUser.occupation}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              UA1
            </Col>
            <Col span={17}>{amodoUser.ua1}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              UA2
            </Col>
            <Col span={17}>{amodoUser.ua2}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              UA3
            </Col>
            <Col span={17}>{amodoUser.ua3}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              UA4
            </Col>
            <Col span={17}>{amodoUser.ua4}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              UA5
            </Col>
            <Col span={17}>{amodoUser.ua5}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              UA6
            </Col>
            <Col span={17}>{amodoUser.ua6}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Status
            </Col>
            <Col span={17}>{amodoUser.status}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Progress
            </Col>
            <Col span={17}>{amodoUser.progress}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Quote Generated
            </Col>
            <Col span={17}>{amodoUser.quote_generated}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Start Time
            </Col>
            <Col span={17}>{amodoUser.start_time}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Finish Time
            </Col>
            <Col span={17}>{amodoUser.finish_time}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Number of Trips
            </Col>
            <Col span={17}>{amodoUser.number_of_trips}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Distance
            </Col>
            <Col span={17}>{amodoUser.distance}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Speeding
            </Col>
            <Col span={17}>{amodoUser.speeding}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Safe Hours
            </Col>
            <Col span={17}>{amodoUser.safe_hours}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Braking
            </Col>
            <Col span={17}>{amodoUser.braking}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Distance 20
            </Col>
            <Col span={17}>{amodoUser.distance_20}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Distance 70
            </Col>
            <Col span={17}>{amodoUser.distance_70}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Average Miles
            </Col>
            <Col span={17}>{amodoUser.average_miles}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Idle Time
            </Col>
            <Col span={17}>{amodoUser.idle_time}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Deceleration
            </Col>
            <Col span={17}>{amodoUser.multi_deceleration}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Speeding on Motorway
            </Col>
            <Col span={17}>{amodoUser.speeding_on_motorway}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Fiddling Time Per Mile
            </Col>
            <Col span={17}>{amodoUser.fiddling_time_per_mile}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Combined Score
            </Col>
            <Col span={17}>{amodoUser.combined_score}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              License Number
            </Col>
            <Col span={17}>{amodoUser.license_number}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Year
            </Col>
            <Col span={17}>{amodoUser.year}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Vehicle Body
            </Col>
            <Col span={17}>{amodoUser.vehicle_body}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Vehicle Model
            </Col>
            <Col span={17}>{amodoUser.vehicle_model}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Vehicle Transmission
            </Col>
            <Col span={17}>{amodoUser.vehicle_transmission}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Vehicle Fuel
            </Col>
            <Col span={17}>{amodoUser.vehicle_fuel}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Value
            </Col>
            <Col span={17}>{amodoUser.value}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Weight
            </Col>
            <Col span={17}>{amodoUser.weight}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Power
            </Col>
            <Col span={17}>{amodoUser.power}</Col>
          </Row>
          <Row {...inputRowStyle}>
            <Col span={7} {...titleStyle}>
              Insurance Group
            </Col>
            <Col span={17}>{amodoUser.insurance_group}</Col>
          </Row>
        </Card>
        <Divider />
        <Card>
          <Tabs defaultActiveKey="1">
            {Object.keys(apiUsers).map((key) => {
              return (
                <TabPane
                  tab={
                    <span>
                      {apiUsers[key].brand === "Apple" ? (
                        <AppleOutlined />
                      ) : (
                        <AndroidOutlined />
                      )}
                      {apiUsers[key].brand}
                    </span>
                  }
                  key={key}
                >
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      Notification
                    </Col>
                    <Col span={17}>{apiUsers[key].notification}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      Android Background Location
                    </Col>
                    <Col span={17}>
                      {apiUsers[key].android_access_background_location}
                    </Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      Android Fine Location
                    </Col>
                    <Col span={17}>
                      {apiUsers[key].android_access_fine_location}
                    </Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      Android Activity Recognition
                    </Col>
                    <Col span={17}>
                      {apiUsers[key].android_activity_recognition}
                    </Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      Android Id
                    </Col>
                    <Col span={17}>{apiUsers[key].android_id}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      Android External Storage
                    </Col>
                    <Col span={17}>
                      {apiUsers[key].android_write_external_storage}
                    </Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      Brand
                    </Col>
                    <Col span={17}>{apiUsers[key].brand}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      Created At
                    </Col>
                    <Col span={17}>{apiUsers[key].created_at}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      Id
                    </Col>
                    <Col span={17}>{apiUsers[key].id}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      IOS Ad Id
                    </Col>
                    <Col span={17}>{apiUsers[key].ios_advertising_id}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      IOS Location Always
                    </Col>
                    <Col span={17}>{apiUsers[key].ios_location_always}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      IOS Location When In Use
                    </Col>
                    <Col span={17}>
                      {apiUsers[key].ios_location_when_in_use}
                    </Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      IOS Motion
                    </Col>
                    <Col span={17}>{apiUsers[key].ios_motion}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      Ios Vendor Id
                    </Col>
                    <Col span={17}>{apiUsers[key].ios_vendor_id}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      Latest App Build Number
                    </Col>
                    <Col span={17}>{apiUsers[key].latest_app_build_number}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      Latest App Version
                    </Col>
                    <Col span={17}>{apiUsers[key].latest_app_version}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      Model
                    </Col>
                    <Col span={17}>{apiUsers[key].model}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      System OS Build Id
                    </Col>
                    <Col span={17}>{apiUsers[key].system_os_build_id}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      System Type
                    </Col>
                    <Col span={17}>{apiUsers[key].system_type}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      System Version
                    </Col>
                    <Col span={17}>{apiUsers[key].system_version}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      Updated At
                    </Col>
                    <Col span={17}>{apiUsers[key].updated_at}</Col>
                  </Row>{" "}
                  <Row {...inputRowStyle}>
                    <Col span={7} {...titleStyle}>
                      User Id
                    </Col>
                    <Col span={17}>{apiUsers[key].user_id}</Col>
                  </Row>
                </TabPane>
              );
            })}
          </Tabs>
        </Card>
        <Divider />
        <Card>
          {answers &&
            Object.keys(answers).map((key) => {
              return (
                <Row key={answers[key].id} {...inputRowStyle}>
                  <Col span={7} {...titleStyle}>
                    {answers[key].question_text}
                  </Col>
                  <Col span={10}>
                    {answers[key].answer_text || answers[key].custom_value}
                  </Col>
                  <Col span={7}>{answers[key].created_at}</Col>
                </Row>
              );
            })}
        </Card>
      </Col>
    </Row>
  );
};

export default AmodoDataDetailPage;
