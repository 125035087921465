export const InitialState = {
  isAlertVisible: false,
  title: "",
  content: "",
  onOk: (): void => {
    console.log("Accepted");
  },
  onCancel: (): void => {
    console.log("Cancelled");
  },
};

export type StateType = {
  isAlertVisible: boolean;
  title: string;
  content: string;
  onOk?: () => void;
  okText?: string;
  onCancel?: () => void;
  cancelText?: string;
};
