import React, { FC } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteComponentProps,
} from "react-router-dom";
import LoginPage from "pages/login/LoginPage";
import RegisterPage from "pages/register/RegisterPage";
import Alert from "components/alert/Alert";
import CheckAuth from "components/checkAuth/CheckAuth";
import Theme from "navigator/theme/Theme";
import UsersPage from "pages/users/UsersPage";
import QuestionsPage from "pages/questions/QuestionsPage";
import QuestionDetailPage from "pages/questions/QuestionDetailPage";
import AnswersPage from "pages/answers/AnswersPage";
import NCDPage from "pages/ncd/NCDPage";
import AmodoDataPage from "pages/amodoData/AmodoDataPage";
import AmodoDataDetailPage from "pages/amodoData/AmodoDataDetailPage";
import ReferralPage from "pages/referral/ReferralPage";
import DevicesPage from "pages/devices/DevicesPage";
import QuotesPage from "pages/quotes/QuotesPage";
import DrivingScorePage from "pages/userDrivingScore/UserDrivingScore";
import DashboardPage from "pages/dashboard/DashboardPage";
import MOTFailurePage from "pages/motFailure/MOTFailurePage";
import VehicleExtendedDataPage from "pages/vehicle-extended-data/VehicleExtendedData";
import LinksPage from "pages/links/LinksPage";
import { BreakdownPage } from "pages/breakdowns/BreakdownsPage";

export interface IRoutesProps {}

const Routes: FC<IRoutesProps> = () => {
  return (
    <Router>
      <Alert />
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/register">
          <RegisterPage />
        </Route>
        <CheckAuth>
          <Theme>
            <Route exact path="/">
              <UsersPage />
            </Route>
            <Route exact path="/breakdown">
              <BreakdownPage />
            </Route>
            <Route exact path="/dashboard">
              <DashboardPage />
            </Route>
            <Route exact path="/questions">
              <QuestionsPage />
            </Route>
            <Route
              exact
              path="/question/:id"
              render={({ match }: RouteComponentProps<any>) => (
                <QuestionDetailPage id={match.params.id} />
              )}
            />
            <Route exact path="/answers">
              <AnswersPage />
            </Route>
            <Route exact path="/driving-score">
              <DrivingScorePage />
            </Route>
            <Route exact path="/ncd">
              <NCDPage />
            </Route>
            <Route exact path="/amodo-data">
              <AmodoDataPage />
            </Route>
            <Route
              exact
              path="/amodo-data-detail/:email"
              render={({ match }: RouteComponentProps<any>) => {
                return <AmodoDataDetailPage email={match.params.email} />;
              }}
            />
            <Route exact path="/referral">
              <ReferralPage />
            </Route>
            <Route exact path="/devices">
              <DevicesPage />
            </Route>
            <Route exact path="/quotes">
              <QuotesPage />
            </Route>
            <Route exact path="/mot-failure">
              <MOTFailurePage />
            </Route>
            <Route exact path="/vehicle-extended-data">
              <VehicleExtendedDataPage />
            </Route>
            <Route exact path="/links">
              <LinksPage />
            </Route>
          </Theme>
        </CheckAuth>
      </Switch>
    </Router>
  );
};

export default Routes;
