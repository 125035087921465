import { Action, ActionTypes } from "./Types";
import { logoutRequest } from "api/Api";

export const login = (authToken: string): Action => {
  localStorage.setItem("authToken", authToken);
  return {
    type: ActionTypes.AUTH_SUCCESS,
    payload: { isLoggedIn: true, authToken: authToken },
  };
};

export const logout = (): Action => {
  logoutRequest();
  localStorage.removeItem("authToken");
  return {
    type: ActionTypes.AUTH_SUCCESS,
    payload: { isLoggedIn: false, authToken: "" },
  };
};
