import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getRenewalsData } from "api/Api";

const RenewalsPage = () => {
  const [data, setData] = useState(initialDataStructure);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await getRenewalsData();
      setData(response);
    } catch (error) {
      console.error("An error occurred while fetching the data:", error);

      setData(initialDataStructure);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const dataSource = [
    {
      key: "1",
      metric: "Quotes generated",
      today: data.renewalQuoteStats.quote_offered_today,
      yesterday: data.renewalQuoteStats.quote_offered_yesterday,
      dayPercentage: data.renewalQuoteStats.quote_offered_day_percentage,
      thisWeek: data.renewalQuoteStats.quote_offered_this_week,
      lastWeek: data.renewalQuoteStats.quote_offered_last_week,
      weekPercentage: data.renewalQuoteStats.quote_offered_this_week_percentage,
      thisMonth: data.renewalQuoteStats.quote_offered_this_month,
      lastMonth: data.renewalQuoteStats.quote_offered_last_month,
      monthPercentage:
        data.renewalQuoteStats.quote_offered_this_month_percentage,
    },
  ];

  const columns = [
    {
      title: "Metric",
      dataIndex: "metric",
      key: "metric",
      width: 400,
    },
    {
      title: "Today",
      dataIndex: "today",
      key: "today",
    },
    {
      title: "Yesterday",
      dataIndex: "yesterday",
      key: "yesterday",
    },
    {
      title: "Day %",
      dataIndex: "dayPercentage",
      key: "dayPercentage",
    },
    {
      title: "This Week",
      dataIndex: "thisWeek",
      key: "thisWeek",
    },
    {
      title: "Last Week",
      dataIndex: "lastWeek",
      key: "lastWeek",
    },
    {
      title: "Week %",
      dataIndex: "weekPercentage",
      key: "weekPercentage",
    },
    {
      title: "This Month",
      dataIndex: "thisMonth",
      key: "thisMonth",
    },
    {
      title: "Last Month",
      dataIndex: "lastMonth",
      key: "lastMonth",
    },
    {
      title: "Month %",
      dataIndex: "monthPercentage",
      key: "monthPercentage",
    },
  ];

  return (
    <Table
      loading={loading}
      dataSource={data && dataSource}
      columns={columns}
      scroll={{ x: "max-content" }}
      tableLayout="fixed"
      pagination={false}
      bordered
    />
  );
};

export default RenewalsPage;

const initialDataStructure = {
  renewalQuoteStats: {
    quote_offered_today: "0",
    quote_offered_yesterday: "0",
    quote_offered_this_week: "0",
    quote_offered_this_month: "0",
    quote_offered_last_week: "0",
    quote_offered_last_month: "0",
    quote_offered_day_percentage: 0,
    quote_offered_this_week_percentage: 0,
    quote_offered_this_month_percentage: 0,
  },
};
