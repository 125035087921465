import { Action, ActionTypes } from "./Types";

type openModelProps = {
  title: string;
  content: string;
  onOk?: () => void;
  okText?: string;
  onCancel?: () => void;
  cancelText?: string;
};

export const showAlert = ({
  title,
  content,
  onOk,
  okText,
  onCancel,
  cancelText,
}: openModelProps): Action => {
  return {
    type: ActionTypes.ALERT_OPEN,
    payload: {
      isAlertVisible: true,
      title: title,
      content: content,
      onOk: onOk,
      okText: okText,
      onCancel: onCancel,
      cancelText: cancelText,
    },
  };
};

export const closeAlert = (): Action => {
  return {
    type: ActionTypes.ALERT_CLOSE,
    payload: { isAlertVisible: false },
  };
};
