import React, { FC, useEffect, useState } from "react";
import { listAmodoData, uploadAmodoData } from "api/Api";
import {
  Row,
  Col,
  Table,
  Input,
  Upload,
  Button,
  message,
  Switch,
  Select,
  DatePicker,
} from "antd";
import { AmodoUser } from "../../types/AmodoUser";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { BarsOutlined, UploadOutlined, SaveOutlined } from "@ant-design/icons";
import { CSVDownload } from "react-csv";

export interface IAmodoDataPageProps {}

const AmodoDataPage: FC<IAmodoDataPageProps> = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<Array<AmodoUser>>([]);
  const [count, setCount] = useState<number>(0);
  const [searchVal, setSearchVal] = useState<string>("");
  const [showProgress, setShowProgress] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(false);

  const [minCurrentPrice, setMinCurrentPrice] = useState<string>("");
  const [maxCurrentPrice, setMaxCurrentPrice] = useState<string>("");
  const [minQuotePrice, setMinQuotePrice] = useState<string>("");
  const [maxQuotePrice, setMaxQuotePrice] = useState<string>("");
  const [minProgress, setMinProgress] = useState<string>("");
  const [maxProgress, setMaxProgress] = useState<string>("");
  const [minNumberOfTrips, setMinNumberOfTrips] = useState<string>("");
  const [maxNumberOfTrips, setMaxNumberOfTrips] = useState<string>("");
  const [minDistance, setMinDistance] = useState<string>("");
  const [maxDistance, setMaxDistance] = useState<string>("");
  const [minCombinedScore, setMinCombinedScore] = useState<string>("");
  const [maxCombinedScore, setMaxCombinedScore] = useState<string>("");
  const [renewalMonth, setRenewalMonth] = useState<string>("");
  const [renewalDay, setRenewalDay] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [startBirth, setStartBirth] = useState<any>("");
  const [endBirth, setEndBirth] = useState<any>("");
  const [csvData, setCsvData] = useState<string>("");

  const func = async ({
    page = 0,
    download,
  }: {
    page?: number;
    download?: boolean;
  }) => {
    setLoading(true);
    try {
      let limit = 10;
      if (download) {
        page = undefined;
        limit = undefined;
      }
      const query = `&text=${searchVal}&minCurrentPrice=${minCurrentPrice}&maxCurrentPrice=${maxCurrentPrice}&minQuotePrice=${minQuotePrice}&maxQuotePrice=${maxQuotePrice}&minProgress=${minProgress}&maxProgress=${maxProgress}&minNumberOfTrips=${minNumberOfTrips}&maxNumberOfTrips=${maxNumberOfTrips}&minDistance=${minDistance}&maxDistance=${maxDistance}&minCombinedScore=${minCombinedScore}&maxCombinedScore=${maxCombinedScore}&renewalMonth=${renewalMonth}&renewalDay=${renewalDay}&status=${status}&startDate=${startDate}&endDate=${endDate}&startBirth=${startBirth}&endBirth=${endBirth}&download=${download}`;
      const result = await listAmodoData({ page, limit, query });
      setLoading(false);
      if (download) {
        setCsvData(result.data);
      } else {
        setCount(result.data.total_results);
        setUsers(
          result.data.results.map((user: any, index: number) => {
            return { index, key: user.user_id, ...user };
          })
        );
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (csvData) {
      setCsvData(null);
    }
  }, [csvData]);

  const clear = () => {
    setSearchVal("");
    setMinCurrentPrice("");
    setMaxCurrentPrice("");
    setMinQuotePrice("");
    setMaxQuotePrice("");
    setMinProgress("");
    setMaxProgress("");
    setMinNumberOfTrips("");
    setMaxNumberOfTrips("");
    setMinDistance("");
    setMaxDistance("");
    setMinCombinedScore("");
    setMaxCombinedScore("");
    setRenewalMonth("");
    setRenewalDay("");
    setStatus("");
    setStartDate("");
    setEndDate("");
    setStartBirth("");
    setEndBirth("");
  };

  useEffect(() => {
    func({});
  }, []);

  return (
    <Row>
      <Col span={18} offset={3}>
        <Row style={{ marginBottom: 10 }}>
          <Col span={6}>
            <Input.Search
              placeholder="Search"
              onChange={(e) => {
                setSearchVal(e.target.value);
              }}
              onSearch={() => {
                func({});
              }}
            />
          </Col>
          <Col span={4} style={{ marginLeft: 5, lineHeight: "35px" }}>
            Total: {count}
          </Col>
          <Col span={10} offset={3} style={{ textAlign: "right" }}>
            <Switch
              checked={checked}
              onChange={(c) => {
                setChecked(c);
              }}
              style={{ marginRight: 8 }}
              checkedChildren="Hide Filters"
              unCheckedChildren="Show Filters"
            />
            <Button
              style={{ marginRight: 8 }}
              onClick={() => clear()}
              type="primary"
            >
              Clear
            </Button>
            {csvData && <CSVDownload data={csvData} target="_blank" />}
            <Button
              disabled={loading}
              style={{ marginRight: 8 }}
              onClick={async () => {
                func({ download: true });
              }}
            >
              <SaveOutlined />
            </Button>
            <Upload
              showUploadList={showProgress}
              customRequest={async (data: any) => {
                try {
                  data.onProgress({ percent: 70 });
                  setShowProgress(true);
                  await uploadAmodoData(data.file, data.file.name);
                  data.onSuccess();
                  message.success(`file uploaded successfully`);
                  setShowProgress(false);
                } catch (err) {
                  data.onError(err);
                  message.error(`file upload failed.`);
                }
              }}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Col>
        </Row>
        {checked && (
          <>
            <Row>
              <Col span={8} style={{ marginBottom: 10 }}>
                <Row style={{ alignItems: "center" }}>
                  <Col span={8}>Current Price</Col>
                  <Col span={7}>
                    <Input
                      placeholder="Min"
                      value={minCurrentPrice}
                      onChange={(e) => {
                        setMinCurrentPrice(e.target.value);
                      }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                    <Input
                      placeholder="Max"
                      value={maxCurrentPrice}
                      onChange={(e) => {
                        setMaxCurrentPrice(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} style={{ marginBottom: 10 }}>
                <Row style={{ alignItems: "center" }}>
                  <Col span={1} />
                  <Col span={7}>Quote Price</Col>
                  <Col span={7}>
                    <Input
                      placeholder="Min"
                      value={minQuotePrice}
                      onChange={(e) => {
                        setMinQuotePrice(e.target.value);
                      }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                    <Input
                      placeholder="Max"
                      value={maxQuotePrice}
                      onChange={(e) => {
                        setMaxQuotePrice(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} style={{ marginBottom: 10 }}>
                <Row style={{ alignItems: "center" }}>
                  <Col span={1} />
                  <Col span={7}>Progress</Col>
                  <Col span={7}>
                    <Input
                      placeholder="Min"
                      value={minProgress}
                      onChange={(e) => {
                        setMinProgress(e.target.value);
                      }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                    <Input
                      placeholder="Max"
                      value={maxProgress}
                      onChange={(e) => {
                        setMaxProgress(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ marginBottom: 10 }}>
                <Row style={{ alignItems: "center" }}>
                  <Col span={8}>Num. Of Trips</Col>
                  <Col span={7}>
                    <Input
                      placeholder="Min"
                      value={minNumberOfTrips}
                      onChange={(e) => {
                        setMinNumberOfTrips(e.target.value);
                      }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                    <Input
                      placeholder="Max"
                      value={maxNumberOfTrips}
                      onChange={(e) => {
                        setMaxNumberOfTrips(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} style={{ marginBottom: 10 }}>
                <Row style={{ alignItems: "center" }}>
                  <Col span={6}>Distance</Col>
                  <Col span={7}>
                    <Input
                      placeholder="Min"
                      value={minDistance}
                      onChange={(e) => {
                        setMinDistance(e.target.value);
                      }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                    <Input
                      placeholder="Max"
                      value={maxDistance}
                      onChange={(e) => {
                        setMaxDistance(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={8} style={{ marginBottom: 10 }}>
                <Row style={{ alignItems: "center" }}>
                  <Col span={9}>Combined Score</Col>
                  <Col span={7}>
                    <Input
                      placeholder="Min"
                      value={minCombinedScore}
                      onChange={(e) => {
                        setMinCombinedScore(e.target.value);
                      }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                    <Input
                      placeholder="Max"
                      value={maxCombinedScore}
                      onChange={(e) => {
                        setMaxCombinedScore(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={9} style={{ marginBottom: 10 }}>
                <Row style={{ alignItems: "center" }}>
                  <Col span={5}>Start Date</Col>
                  <Col span={1} />
                  <Col span={7}>
                    <DatePicker
                      format="yyyy-MM-DD"
                      onChange={(e) => {
                        const m = moment(e).format("yyyy-MM-DD").toString();
                        setStartDate(m);
                      }}
                      placeholder="Start"
                      value={startDate.length > 0 ? moment(startDate) : null}
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                    <DatePicker
                      format="yyyy-MM-DD"
                      onChange={(e) => {
                        const m = moment(e).format("yyyy-MM-DD").toString();
                        setEndDate(m);
                      }}
                      placeholder="End"
                      value={endDate.length > 0 ? moment(endDate) : null}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={5} style={{ marginBottom: 10 }}>
                <Select
                  placeholder="Renewal Month"
                  defaultValue={null}
                  value={renewalMonth.length > 0 ? renewalMonth : null}
                  style={{ width: "80%" }}
                  onChange={(e) => {
                    setRenewalMonth(e);
                  }}
                >
                  <Select.Option value="24">January</Select.Option>
                  <Select.Option value="25">February</Select.Option>
                  <Select.Option value="26">March</Select.Option>
                  <Select.Option value="27">April</Select.Option>
                  <Select.Option value="28">May</Select.Option>
                  <Select.Option value="29">June</Select.Option>
                  <Select.Option value="30">July</Select.Option>
                  <Select.Option value="31">August</Select.Option>
                  <Select.Option value="32">September</Select.Option>
                  <Select.Option value="33">October</Select.Option>
                  <Select.Option value="34">November</Select.Option>
                  <Select.Option value="35">December</Select.Option>
                </Select>
              </Col>
              <Col span={5} style={{ marginBottom: 10 }}>
                <Select
                  placeholder="Renewal Day"
                  value={renewalDay.length > 0 ? renewalDay : null}
                  defaultValue={null}
                  style={{ width: "80%" }}
                  onChange={(e) => {
                    setRenewalDay(e);
                  }}
                >
                  <Select.Option value="36">1</Select.Option>
                  <Select.Option value="37">2</Select.Option>
                  <Select.Option value="38">3</Select.Option>
                  <Select.Option value="39">4</Select.Option>
                  <Select.Option value="40">5</Select.Option>
                  <Select.Option value="41">6</Select.Option>
                  <Select.Option value="42">7</Select.Option>
                  <Select.Option value="43">8</Select.Option>
                  <Select.Option value="44">9</Select.Option>
                  <Select.Option value="45">10</Select.Option>
                  <Select.Option value="46">11</Select.Option>
                  <Select.Option value="47">12</Select.Option>
                  <Select.Option value="48">13</Select.Option>
                  <Select.Option value="49">14</Select.Option>
                  <Select.Option value="50">15</Select.Option>
                  <Select.Option value="51">16</Select.Option>
                  <Select.Option value="52">17</Select.Option>
                  <Select.Option value="53">18</Select.Option>
                  <Select.Option value="54">19</Select.Option>
                  <Select.Option value="55">20</Select.Option>
                  <Select.Option value="56">21</Select.Option>
                  <Select.Option value="57">22</Select.Option>
                  <Select.Option value="58">23</Select.Option>
                  <Select.Option value="59">24</Select.Option>
                  <Select.Option value="60">25</Select.Option>
                  <Select.Option value="61">26</Select.Option>
                  <Select.Option value="62">27</Select.Option>
                  <Select.Option value="63">28</Select.Option>
                  <Select.Option value="64">29</Select.Option>
                  <Select.Option value="65">30</Select.Option>
                  <Select.Option value="66">31</Select.Option>
                </Select>
              </Col>
              <Col span={5} style={{ marginBottom: 10 }}>
                <Select
                  placeholder="Status"
                  value={status.length > 0 ? status : null}
                  defaultValue={null}
                  style={{ width: "80%" }}
                  onChange={(e) => {
                    setStatus(e);
                  }}
                >
                  <Select.Option value="f">F</Select.Option>
                  <Select.Option value="ip">IP</Select.Option>
                  <Select.Option value="r">R</Select.Option>
                </Select>
              </Col>
            </Row>
            <Row style={{ alignItems: "center", marginBottom: 10 }}>
              <Col span={1}>Birth</Col>
              <Col span={5}>
                <DatePicker
                  format="yyyy-MM-DD"
                  onChange={(e) => {
                    const m = moment(e).format("yyyy-MM-DD").toString();
                    setStartBirth(m);
                  }}
                  placeholder="From"
                  value={startBirth.length > 0 ? moment(startBirth) : null}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={1} />
              <Col span={5}>
                <DatePicker
                  format="yyyy-MM-DD"
                  onChange={(e) => {
                    const m = moment(e).format("yyyy-MM-DD").toString();
                    setEndBirth(m);
                  }}
                  placeholder="To"
                  value={endBirth.length > 0 ? moment(endBirth) : null}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={1} />
              <Col span={3}>
                <Button
                  disabled={loading}
                  onClick={() => func({})}
                  type="primary"
                >
                  Filter Results
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Table
          scroll={{ x: 1300 }}
          loading={loading}
          pagination={{
            showSizeChanger: false,
            pageSize: 10,
            total: count,
            onChange: (page) => {
              func({ page });
            },
          }}
          dataSource={users}
          columns={[
            { title: "Username", dataIndex: "email_address" },
            { title: "First Name", dataIndex: "first_name" },
            { title: "Last Name", dataIndex: "last_name" },
            { title: "Birth", dataIndex: "birth" },
            { title: "Full Address", dataIndex: "full_address" },
            { title: "Postal Code", dataIndex: "postal_code" },
            { title: "Current Price", dataIndex: "current_price" },
            { title: "Quote Price", dataIndex: "quote_price" },
            { title: "Notes", dataIndex: "notes" },
            { title: "Pay Frequency", dataIndex: "pay_freq" },
            { title: "Overnight Parking", dataIndex: "overnight_parking" },
            { title: "No Claims", dataIndex: "no_claims" },
            { title: "Renewal Month", dataIndex: "renewal_month" },
            { title: "Renewal Day", dataIndex: "renewal_day" },
            { title: "License Type", dataIndex: "licence_type" },
            { title: "Occupation", dataIndex: "occupation" },
            { title: "Industry", dataIndex: "industry" },
            { title: "Quote Generated", dataIndex: "quote_generated" },
            { title: "Status", dataIndex: "status" },
            { title: "Progress", dataIndex: "progress" },
            { title: "Start Time", dataIndex: "start_time" },
            { title: "Finish Time", dataIndex: "finish_time" },
            { title: "Number of Trips", dataIndex: "number_of_trips" },
            { title: "Distance", dataIndex: "distance" },
            { title: "Combined Score", dataIndex: "combined_score" },
            { title: "Brand", dataIndex: "brand" },
            { title: "Ios Location Always", dataIndex: "ios_location_always" },
            {
              title: "Android Background Location",
              dataIndex: "android_background_location",
            },
            {
              title: "Details",
              dataIndex: "",
              key: "x",
              render: function renderAction({ index }) {
                return (
                  <a
                    onClick={() => {
                      history.push(
                        "/amodo-data-detail/" + users[index].email_address
                      );
                    }}
                  >
                    <BarsOutlined />
                  </a>
                );
              },
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default AmodoDataPage;
