import React, { FC, useState, useEffect } from "react";
import { quoteUpdate } from "api/Api";
import { Row, Col, Table, Button, Input } from "antd";
const { TextArea } = Input;
import "react-datepicker/dist/react-datepicker.css";
import { Quote } from "../../types/Quote";
import { CSVDownload } from "react-csv";

import { SaveOutlined, PlusOutlined } from "@ant-design/icons";

export interface IQuotesPageProps {}

const QuotesPage: FC<IQuotesPageProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [textAreaSubmited, setTextAreaSubmited] = useState<boolean>(false);
  const [quotes, setQuotes] = useState<Array<Quote>>([]);
  const [count, setCount] = useState<number>(0);
  const [csvData, setCsvData] = useState<boolean>(false);
  const [textAreaVal, setTextAreaVal] = useState<string>("");

  const func = async () => {
    setLoading(true);
    const newVal = textAreaVal
      .replace(/^\s\s*/, "")
      .replace(/\s\s*$/, "")
      .replace(/ |\n/g, ",")
      .replace(/,+/g, ",");
    setTextAreaVal(newVal);
    const result = await quoteUpdate({
      emailList: newVal,
    });
    setTextAreaSubmited(true);
    setCount(result.data + count);
    setQuotes([...quotes, ...result.data]);
    setLoading(false);
  };

  useEffect(() => {
    console.log(csvData);
    if (csvData == true) {
      console.log("hi");
      setCsvData(false);
    }
  }, [csvData]);

  return (
    <Row>
      <Col style={{ marginTop: 20 }} span={18} offset={3}>
        <>
          {!textAreaSubmited && (
            <>
              <Row style={{ marginBottom: 10 }}>
                <Col offset={5} span={12}>
                  <TextArea
                    onChange={(e) => setTextAreaVal(e.target.value)}
                    value={textAreaVal}
                    style={{ height: 250 }}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Col offset={5} span={12}>
                  <Button
                    loading={loading}
                    style={{ float: "right" }}
                    onClick={async () => {
                      func();
                    }}
                  >
                    <SaveOutlined />
                  </Button>
                </Col>
              </Row>
            </>
          )}
          {textAreaSubmited && (
            <Row>
              <Col>
                {csvData && <CSVDownload data={quotes} target="_blank" />}
                <Button
                  style={{ float: "right", marginBottom: 10 }}
                  onClick={async () => {
                    setCsvData(true);
                  }}
                >
                  <SaveOutlined />
                </Button>
                <Button
                  style={{ float: "right", marginBottom: 10, marginRight: 5 }}
                  onClick={async () => {
                    setTextAreaSubmited(false);
                    setTextAreaVal("");
                  }}
                >
                  <PlusOutlined />
                </Button>
                <Table
                  scroll={{ x: "max-content" }}
                  loading={loading}
                  pagination={{
                    showSizeChanger: false,
                    pageSize: 10,
                    total: count,
                    /*onChange: () => {
                      func();
                    },*/
                  }}
                  dataSource={quotes}
                  columns={[
                    { title: "Message", dataIndex: "message" },
                    { title: "Status", dataIndex: "status" },
                    { title: "Hash", dataIndex: "hash" },
                    { title: "Email", dataIndex: "email" },
                    { title: "Price", dataIndex: "price" },
                    { title: "TransactionId", dataIndex: "transactionId" },
                    { title: "tmDeclineText", dataIndex: "tmDeclineText" },
                    { title: "pcDeclineText", dataIndex: "pcDeclineText" },
                    { title: "tmPrice", dataIndex: "tmPrice" },
                    { title: "pcPrice", dataIndex: "pcPrice" },
                    { title: "Url", dataIndex: "checkoutURL" },
                  ]}
                />
              </Col>
            </Row>
          )}
        </>
      </Col>
    </Row>
  );
};

export default QuotesPage;
