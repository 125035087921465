import React, { FC, useEffect, useState } from "react";
import { listQuestions } from "api/Api";
import { Row, Col, Table, Input } from "antd";
import { Question } from "../../types/Question";
import { useHistory } from "react-router-dom";

import { EditOutlined } from "@ant-design/icons";

export interface IQuestionsPageProps {}

const QuestionsPage: FC<IQuestionsPageProps> = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Array<Question>>([]);
  const [count, setCount] = useState<number>(0);
  const [searchVal, setSearchVal] = useState<string>("");

  const func = async ({ page, query }: { page?: number; query?: string }) => {
    setLoading(true);
    const result = await listQuestions({ page, query });
    setLoading(false);
    setCount(result.data.total_results);
    setQuestions(
      result.data.results.map(({ id, text }: any) => {
        return { key: id, text };
      })
    );
  };

  useEffect(() => {
    func({});
  }, []);

  return (
    <Row>
      <Col span={18} offset={3}>
        <Col span={6} style={{ marginBottom: 10 }}>
          <Input.Search
            placeholder="Search"
            onSearch={(val) => {
              setSearchVal(val);
              func({ query: val });
            }}
          />
        </Col>
        <Table
          loading={loading}
          pagination={{
            showSizeChanger: false,
            pageSize: 10,
            total: count,
            onChange: (page) => {
              func({ page, query: searchVal });
            },
          }}
          dataSource={questions}
          columns={[
            { title: "Question", dataIndex: "text" },
            {
              title: "Action",
              dataIndex: "",
              key: "x",
              render: function renderAction({ key }) {
                return (
                  <a
                    onClick={() => {
                      history.push("/question/" + key);
                    }}
                  >
                    <EditOutlined />
                  </a>
                );
              },
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default QuestionsPage;
