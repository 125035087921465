import { create } from "apisauce";
import firebase from "./Firebase";

import {
  LoginRequest,
  RegisterRequest,
  RegisterResponse,
  UserListResponse,
  QuestionListResponse,
  QuestionDetailRequest,
  QuestionDetailResponse,
  AnswerListResponse,
  NCDRequest,
  NCDResponse,
  AmodoDataResponse,
  AmodoDataDetailRequest,
  AmodoDataDetailResponse,
  BaseRequest,
  AmodoDataUpdateResponse,
  AmodoDataUpdateRequest,
  ReferralStateListResponse,
  QuoteUpdateRequest,
  QuoteUpdateResponse,
  UpdateStatusRequest,
  UpdateStatusResponse,
  DrivingScoreListResponse,
  DrivingScoreRequest,
  CreateQuoteRequest,
  CreateQuoteResponse,
  DeleteUserRequest,
  DeleteUserResponse,
  CreateBulkQuoteRequest,
  UpdateVehicleData,
  GetMOTFailureData,
} from "./ApiTypings.d";
import moment from "moment";

const api = create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 60 * 1000 * 10,
});

const dataApi = create({
  baseURL: process.env.REACT_APP_DATA_API_BASE_URL,
  timeout: 60 * 1000 * 10,
});

api.addAsyncRequestTransform((request) => async () => {
  await asyncRequest(request);
});

api.addResponseTransform((response) => {
  responseTransform(response);
});

dataApi.addAsyncRequestTransform((request) => async () => {
  await asyncRequest(request);
});

dataApi.addResponseTransform((response) => {
  responseTransform(response);
});

const asyncRequest = async (request: any) => {
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    let token;
    const auth = firebase.auth();
    if (!auth.currentUser) {
      token = await new Promise(async (resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(async (user: any) => {
          if (user) {
            unsubscribe();
            const t = await user.getIdToken(true);
            resolve(t);
          } else {
            resolve(null);
          }
        }, reject);
      });
    } else {
      token = await auth.currentUser.getIdToken(true);
    }
    request.headers["Authorization"] = `Bearer ${token}`;
    request.headers["Content-Type"] = "application/json";
  }
};

const responseTransform = (response: any) => {
  if (!(response.status >= 200 && response.status < 300)) {
    throw response;
  }
};

export const registerRequest = async ({
  email,
  password,
}: RegisterRequest): Promise<RegisterResponse> => {
  return api.post("/auth/register", { email, password });
};

export const loginRequest = async ({
  email,
  password,
}: LoginRequest): Promise<string> => {
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async () => {
      return firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => {
          return token;
        });
    });
};

export const logoutRequest = async (): Promise<any> => {
  return firebase.auth().signOut();
};

export const listUsers = async ({
  page,
  limit,
  query,
  download,
  status,
  sorter,
  filter,
}: BaseRequest & {
  status?: string;
  sorter?: any;
  filter?: any;
}): Promise<UserListResponse> => {
  let queryStr = `/user/list?page=${page}&limit=${limit}`;

  if (query) {
    queryStr += `&query=${encodeURIComponent(query)}`;
  }

  if (status) {
    queryStr += `&status=${encodeURIComponent(status)}`;
  }

  if (sorter && sorter.field && sorter.order) {
    queryStr += `&sortField=${sorter.field}&sortOrder=${sorter.order}`;
  }

  if (filter) {
    for (const key in filter) {
      if (filter[key]) {
        queryStr += `&${key}=${encodeURIComponent(filter[key])}`;
      }
    }
  }

  if (download) {
    queryStr += `&download=true`;
  }

  const response = await api.get(queryStr);

  return response;
};

export const updateUserStatus = async ({
  id,
  status,
}: UpdateStatusRequest): Promise<UpdateStatusResponse> => {
  return api.put("/user/status", { userId: id, status });
};

export const createQuoteRequest = async ({
  userId,
}: CreateQuoteRequest): Promise<CreateQuoteResponse> => {
  return api.post("/quote/user", { userId });
};

export const bulkQuoteRequest = async ({
  userIds,
}: CreateBulkQuoteRequest): Promise<CreateQuoteResponse> => {
  return api.post("/quote/bulk", { userIds });
};

export const listQuestions = async ({
  page = 0,
  limit = 10,
  query,
}: BaseRequest): Promise<QuestionListResponse> => {
  return api.get(
    `/question/list?page=${page}&limit=${limit}` +
      (query ? "&query=" + query : "")
  );
};

export const questionDetail = async ({
  id,
}: QuestionDetailRequest): Promise<QuestionDetailResponse> => {
  return api.get("question/details/" + id);
};

export const listAnswers = async ({
  page = 0,
  limit = 10,
  start_date = moment().subtract(2, "months").toDate(),
  end_date = new Date(),
  query,
}: BaseRequest): Promise<AnswerListResponse> => {
  return api.get(
    `/question/listuseranswers?page=${page}&limit=${limit}&start_date=${
      start_date.getTime() / 1000
    }&end_date=${end_date.getTime() / 1000}` + (query ? "&query=" + query : "")
  );
};

export const downloadAnswers = async ({
  start_date = moment().subtract(2, "months").toDate(),
  end_date = new Date(),
}: BaseRequest): Promise<any> => {
  return api.get(
    `/user/listwithanswers?start_date=${start_date.getTime() / 1000}&end_date=${
      end_date.getTime() / 1000
    }&download=true`
  );
};

export const submitNCD = async ({
  DLN,
  DateOfBirth,
  AddressLine1,
  AddressLine2,
  AddressLine3,
  AddressLine4,
  EffectiveDate,
  FamilyName,
  ForenameInitial,
  GBDriverFlag,
  Gender,
  NCDType,
  NCDValue,
  Postcode,
  ReasonCode,
  VehicleType,
  VRM,
}: NCDRequest): Promise<NCDResponse> => {
  return dataApi.post("/ncd/submit", {
    DLN,
    DateOfBirth: DateOfBirth?.format("yyyy-MM-DD"),
    AddressLine1,
    AddressLine2,
    AddressLine3,
    AddressLine4,
    EffectiveDate: EffectiveDate?.format("yyyy-MM-DD"),
    FamilyName,
    ForenameInitial,
    GBDriverFlag,
    Gender,
    NCDType,
    NCDValue: Number(NCDValue),
    Postcode,
    ReasonCode: Number(ReasonCode),
    VehicleType,
    VRM,
  });
};

export const listAmodoData = async ({
  page,
  limit,
  query,
}: BaseRequest): Promise<AmodoDataResponse> => {
  return dataApi.get(
    `/amododata/list?page=${page}&limit=${limit}` + (query ? query : "")
  );
};

export const amodoDataDetail = async ({
  email,
}: AmodoDataDetailRequest): Promise<AmodoDataDetailResponse> => {
  return dataApi.post(`/amododata/details`, { email });
};

export const uploadAmodoData = async (
  file: Blob,
  fileName: string
): Promise<any> => {
  return firebase
    .storage()
    .ref()
    .child(fileName + new Date().getTime())
    .put(file)
    .then(async (snapshot) => {
      const url = await snapshot.ref.getDownloadURL();
      return dataApi.post("/upload/user-report", { file_url: url });
    });
};

export const updateAmodoData = async ({
  email,
  quotePrice,
  notes,
}: AmodoDataUpdateRequest): Promise<AmodoDataUpdateResponse> => {
  return dataApi.post(`/amododata/update`, { email, quotePrice, notes });
};

export const listReferralStates = async ({
  page = 0,
  limit = 10,
  query,
}: BaseRequest): Promise<ReferralStateListResponse> => {
  return dataApi.get(
    `/referral/list?page=${page}&limit=${limit}` +
      (query.status ? "&status=" + `${query.status}` : "") +
      (query.email ? "&email=" + `${query.email}` : "")
  );
};

export const listDevices = async ({
  page = 0,
  limit = 10,
  query,
}: BaseRequest): Promise<QuestionListResponse> => {
  return dataApi.get(
    `/device/list?page=${page}&limit=${limit}` +
      (query ? "&email=" + encodeURIComponent(query) : "")
  );
};

export const quoteUpdate = async ({
  emailList,
}: QuoteUpdateRequest): Promise<QuoteUpdateResponse> => {
  return dataApi.post(`/quote/update`, { userList: emailList });
};

export const listDrivingScores = async ({
  page,
  limit,
  query,
  sorter,
  filter,
  download = false,
}: DrivingScoreRequest): Promise<DrivingScoreListResponse> => {
  let queryStr = `/user/driving-score?page=${page}&limit=${limit}`;

  if (query) {
    queryStr += `&query=${encodeURIComponent(query)}`;
  }

  if (sorter && sorter.field && sorter.order) {
    queryStr += `&sortField=${sorter.field}&sortOrder=${sorter.order}`;
  } else {
    queryStr += `&sortField=created_at&sortOrder=ASC`;
  }

  if (filter) {
    for (const key in filter) {
      if (filter[key] || filter[key] === 0) {
        queryStr += `&${key}=${encodeURIComponent(filter[key])}`;
      }
    }
  }

  if (download) {
    queryStr += `&download=${download}`;
  }

  const response = await api.get(queryStr);

  return response.data;
};

export const getAllVersion = async (): Promise<any> => {
  const response = await api.get("/device/versions");
  return response.data;
};

export const deleteUserById = async ({
  userId,
}: DeleteUserRequest): Promise<DeleteUserResponse> => {
  return api.delete(`/user/${userId}`);
};

export const getDirectNewBussinessData = async (): Promise<any> => {
  const response = await api.get(`/dashboard/direct`);
  return response.data;
};

export const getPartnerNewBusinessData = async (): Promise<any> => {
  const response = await api.get(`/dashboard/partner`);
  return response.data;
};

export const getRenewalsData = async (): Promise<any> => {
  const response = await api.get(`/dashboard/renewals`);
  return response.data;
};

export const getTotalData = async (): Promise<any> => {
  const response = await api.get(`/dashboard/total`);
  return response.data;
};

export const getBreakdownSBData = async (): Promise<any> => {
  const response = await api.get(`/dashboard/breakdowns`);

  return response.data;
};

export const updateUserById = async (
  userId: string,
  payload: any
): Promise<any> => {
  return api.patch(`/user/${userId}`, payload);
};

export const updateVehicleData = async (
  data: UpdateVehicleData
): Promise<any> => {
  return api.put("/vehicle-data/panel/update", { ...data });
};

export const getMOTFailure = async (data: GetMOTFailureData): Promise<any> => {
  const response = await api.post("/vehicle-data/vehicle-mot-failure", {
    ...data,
  });
  return response.data;
};

export const getVehicleExtendedData = async (vrm: string): Promise<any> => {
  const response = await api.post(
    "/vehicle-data/retrieve-extended-vehicle-data",
    {
      vrm,
    }
  );
  return response.data;
};

export const getUserBreakdown = async ({
  page,
  limit,
  query,
  download,
  status,
  filter,
}: BaseRequest & {
  status?: string;
  download?: boolean;
  filter?: any;
}): Promise<any> => {
  let queryStr = `/user/breakdown/list?page=${page}&limit=${limit}`;

  if (query) {
    queryStr += `&query=${encodeURIComponent(query)}`;
  }

  if (status) {
    queryStr += `&status=${encodeURIComponent(status)}`;
  }

  if (filter) {
    for (const key in filter) {
      if (filter[key] || filter[key] === 0) {
        queryStr += `&${key}=${encodeURIComponent(filter[key])}`;
      }
    }
  }

  if (download) {
    queryStr += `&download=true`;
  }

  const response = await api.get(queryStr);

  return response.data;
};
