import React, { FC, useContext, useState } from "react";
import { Layout, Menu, Divider } from "antd";
import { Context } from "contexts/Provider";
import { logout } from "contexts/auth/Actions";

import {
  TeamOutlined,
  UnorderedListOutlined,
  LogoutOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  FileSearchOutlined,
  ExportOutlined,
  CloudDownloadOutlined,
  DollarOutlined,
  MobileOutlined,
  FileAddOutlined,
  FileProtectOutlined,
  DashboardOutlined,
  WarningOutlined,
  CarOutlined,
  LinkOutlined,
  ToolOutlined,
} from "@ant-design/icons";

import {
  Container,
  FullHeightLayout,
  CustomSider,
  LogoPlaceHolder,
  CustomHeader,
  FullHeightRow,
  HamburgerButton,
  ContentWithPadding,
} from "./themeStyles";

import { useHistory, useLocation } from "react-router-dom";

export interface IThemeProps {
  children: React.ReactNode;
}

const Theme: FC<IThemeProps> = ({ children }: IThemeProps) => {
  const history = useHistory();
  const location = useLocation();

  const {
    state: { auth },
    dispatch,
  } = useContext(Context);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(true);

  return (
    <Container>
      <FullHeightLayout>
        <CustomSider
          collapsible
          collapsed={isSidebarCollapsed}
          onCollapse={setIsSidebarCollapsed}
          trigger={null}
        >
          <LogoPlaceHolder>
            {isSidebarCollapsed ? "R" : "Rooster"}
          </LogoPlaceHolder>
          <Menu defaultSelectedKeys={[location.pathname]} mode="inline">
            <Menu.Item
              key="/"
              icon={<TeamOutlined />}
              onClick={() => {
                history.push("/");
              }}
            >
              Users
            </Menu.Item>
            <Menu.Item
              key="/dashboard"
              icon={<DashboardOutlined />}
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              Dashboard
            </Menu.Item>
            <Menu.Item
              key="/breakdown"
              icon={<ToolOutlined />}
              onClick={() => {
                history.push("/breakdown");
              }}
            >
              Breakdowns
            </Menu.Item>
            <Menu.Item
              key="/questions"
              icon={<UnorderedListOutlined />}
              onClick={() => {
                history.push("/questions");
              }}
            >
              Questions
            </Menu.Item>
            <Menu.Item
              key="/answers"
              icon={<FileSearchOutlined />}
              onClick={() => {
                history.push("/answers");
              }}
            >
              Answer
            </Menu.Item>
            <Menu.Item
              key="/driving-score"
              icon={<FileProtectOutlined />}
              onClick={() => {
                history.push("/driving-score");
              }}
            >
              Users Driving Score
            </Menu.Item>
            <Menu.Item
              key="/ncd"
              icon={<ExportOutlined />}
              onClick={() => {
                history.push("/ncd");
              }}
            >
              NCD
            </Menu.Item>
            <Menu.Item
              key="/amodo-data"
              icon={<CloudDownloadOutlined />}
              onClick={() => {
                history.push("/amodo-data");
              }}
            >
              Amodo Data
            </Menu.Item>
            <Menu.Item
              key="/referral"
              icon={<DollarOutlined />}
              onClick={() => {
                history.push("/referral");
              }}
            >
              Referral
            </Menu.Item>
            <Menu.Item
              key="/devices"
              icon={<MobileOutlined />}
              onClick={() => {
                history.push("/devices");
              }}
            >
              Devices
            </Menu.Item>
            <Menu.Item
              key="/quotes"
              icon={<FileAddOutlined />}
              onClick={() => {
                history.push("/quotes");
              }}
            >
              Quotes
            </Menu.Item>
            <Menu.Item
              key="/mot-failure"
              icon={<WarningOutlined />}
              onClick={() => {
                history.push("/mot-failure");
              }}
            >
              Vehicle MOT Failure
            </Menu.Item>
            <Menu.Item
              key="/vehicle-extended-data"
              icon={<CarOutlined />}
              onClick={() => {
                history.push("/vehicle-extended-data");
              }}
            >
              Vehicle MOT Failure
            </Menu.Item>
            <Menu.Item
              key="/links"
              icon={<LinkOutlined />}
              onClick={() => {
                history.push("/links");
              }}
            >
              Links
            </Menu.Item>
            <Divider />
            {auth.isLoggedIn && (
              <Menu.Item
                key="/logout"
                icon={<LogoutOutlined />}
                onClick={() => dispatch(logout())}
              >
                Logout
              </Menu.Item>
            )}
          </Menu>
        </CustomSider>
        <Layout>
          <CustomHeader collapsed={isSidebarCollapsed}>
            <FullHeightRow align="middle">
              <HamburgerButton
                type="text"
                onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
                size={"large"}
              >
                {isSidebarCollapsed ? (
                  <CaretRightOutlined />
                ) : (
                  <CaretLeftOutlined />
                )}
              </HamburgerButton>
            </FullHeightRow>
          </CustomHeader>
          <ContentWithPadding collapsed={isSidebarCollapsed}>
            {children}
          </ContentWithPadding>
        </Layout>
      </FullHeightLayout>
    </Container>
  );
};

export default Theme;
