import React, { createContext, useReducer, ReactNode, FC } from "react";

import { rootReducer } from "./RootReducer";
import { combinedState, RootStateType } from "./RootState";

export const Context = createContext<RootStateType | any>(combinedState);

interface IAuthContextProps {
  children: ReactNode;
}

const Provider: FC<IAuthContextProps> = ({ children }: IAuthContextProps) => {
  const [state, dispatch] = useReducer(rootReducer, combinedState);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export default Provider;
