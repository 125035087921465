import React, { FC, useState } from "react";
import { Button, Input, Descriptions, Spin, Tooltip } from "antd";
import { getVehicleExtendedData } from "api/Api"; // API çağrıları için kullanılan fonksiyon

export interface IVehicleExtendedDataPageProps {}

const VehicleExtendedDataPage: FC<IVehicleExtendedDataPageProps> = () => {
  const [vrm, setVrm] = useState("");
  const [vehicleData, setVehicleData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const onClickButton = async () => {
    setLoading(true);
    try {
      const data = await getVehicleExtendedData(vrm);
      setVehicleData(data);
    } catch (error) {
      console.error("Error fetching vehicle data:", error);
      setVehicleData(null);
    } finally {
      setLoading(false);
    }
  };

  const renderContent = (content: any) => (
    <Tooltip title={content}>
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "200px",
        }}
      >
        {content}
      </div>
    </Tooltip>
  );

  return (
    <div
      style={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Input
        placeholder="Enter VRM"
        value={vrm}
        onChange={(e) => setVrm(e.target.value)}
        style={{ width: "200px", marginBottom: "10px" }}
      />
      <Button type="primary" onClick={onClickButton} disabled={loading || !vrm}>
        Get Vehicle Data
      </Button>
      {loading ? (
        <Spin size="large" style={{ marginTop: "20px" }} />
      ) : vehicleData ? (
        <Descriptions
          title="Vehicle Extended Data"
          bordered
          column={3}
          style={{
            marginTop: "20px",
            maxWidth: "800px",
            width: "80%",
          }}
          layout="vertical"
        >
          {Object.entries(vehicleData).map(([key, value]) => (
            <Descriptions.Item
              key={key}
              label={
                <span style={{ fontWeight: "bold", fontSize: 18 }}>
                  {key.replace(/([A-Z])/g, " $1").trim()}
                </span>
              }
              span={1}
              style={{ marginBottom: "10px", marginRight: "10px" }}
            >
              {renderContent(value ? value.toString() : "N/A")}
            </Descriptions.Item>
          ))}
        </Descriptions>
      ) : null}
    </div>
  );
};

export default VehicleExtendedDataPage;
