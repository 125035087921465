import React, { FC, useEffect, useState } from "react";
import { questionDetail } from "api/Api";
import { Row, Col, Card, Input, Button } from "antd";
import { Question } from "../../types/Question";

import {
  inputRowStyle,
  actionButtonStyle,
  saveIconStyle,
  deleteIconStyle,
} from "./questionDetailsStyle";

import {
  SaveOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
export interface IQuestionDetailPageProps {
  id: string;
}

const QuestionDetailPage: FC<IQuestionDetailPageProps> = ({
  id,
}: IQuestionDetailPageProps) => {
  const [question, setQuestion] = useState<Question>(Object);

  useEffect(() => {
    const func = async () => {
      const result = await questionDetail({ id });
      setQuestion(result.data);
    };
    func();
  }, []);

  return (
    <Row>
      <Col span={10} offset={7}>
        <Card>
          <h1>{question.text}</h1>
          {question.info && <p>{question.info}</p>}
          {question.answers &&
            question.answers.map((answer) => {
              return (
                <Row key={answer.id} {...inputRowStyle}>
                  <Col span={18}>
                    <Input value={answer.text} /*onChange={handleChange}*/ />
                  </Col>
                  <Col span={6}>
                    <Button {...actionButtonStyle}>
                      <SaveOutlined {...saveIconStyle} />
                    </Button>
                    <Button {...actionButtonStyle}>
                      <DeleteOutlined {...deleteIconStyle} />
                    </Button>
                  </Col>
                </Row>
              );
            })}
          <Row {...inputRowStyle}>
            <Col span={18}>
              <Input value={""} /*onChange={handleChange}*/ />
            </Col>
            <Col span={6}>
              <Button {...actionButtonStyle}>
                <PlusCircleOutlined />
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default QuestionDetailPage;
