export enum ActionTypes {
  ALERT_OPEN = "ALERT_OPEN",
  ALERT_CLOSE = "ALERT_CLOSE",
}

export type Action = AlertOpenAction | AlertCloseAction;

export interface AlertOpenAction {
  type: "ALERT_OPEN";
  payload: {
    isAlertVisible: true;
    title: string;
    content: string;
    onOk?: () => void;
    okText?: string;
    onCancel?: () => void;
    cancelText?: string;
  };
}
export interface AlertCloseAction {
  type: "ALERT_CLOSE";
  payload: { isAlertVisible: false };
}
