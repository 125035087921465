export const inputRowStyle = {
  style: {
    marginTop: 15,
    borderBottom: "1px solid gray",
    alignItems: "center",
  },
};

export const titleStyle = {
  style: { fontWeight: 700 },
};

export const actionButtonStyle = {
  style: { marginTop: 10 },
};

export const saveIconStyle = {
  style: { color: "#1890ff" },
};
