import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getBreakdownSBData } from "api/Api";

const BreakdownSubscriptions = () => {
  const [data, setData] = useState(initialDataStructure);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await getBreakdownSBData();
      setData(response);
    } catch (error) {
      console.error("An error occurred while fetching the data:", error);
      setData(initialDataStructure);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const dataSource = [
    {
      key: "1",
      metric: "Initiate breakdowns",
      today: data.initiateBreakdownSBStats.initiate_today,
      yesterday: data.initiateBreakdownSBStats.initiate_yesterday,
      dayPercentage: data.initiateBreakdownSBStats.daily_change_pct,
      thisWeek: data.initiateBreakdownSBStats.initiate_this_week,
      lastWeek: data.initiateBreakdownSBStats.initiate_last_week,
      weekPercentage: data.initiateBreakdownSBStats.weekly_change_pct,
      thisMonth: data.initiateBreakdownSBStats.initiate_this_month,
      lastMonth: data.initiateBreakdownSBStats.initiate_last_month,
      monthPercentage: data.initiateBreakdownSBStats.monthly_change_pct,
    },
    {
      key: "2",
      metric: "Active breakdowns",
      today: data.activeBreakdownSBStats.active_today,
      yesterday: data.activeBreakdownSBStats.active_yesterday,
      dayPercentage: data.activeBreakdownSBStats.daily_change_pct,
      thisWeek: data.activeBreakdownSBStats.active_this_week,
      lastWeek: data.activeBreakdownSBStats.active_last_week,
      weekPercentage: data.activeBreakdownSBStats.weekly_change_pct,
      thisMonth: data.activeBreakdownSBStats.active_this_month,
      lastMonth: data.activeBreakdownSBStats.active_last_month,
      monthPercentage: data.activeBreakdownSBStats.monthly_change_pct,
    },
    {
      key: "3",
      metric: "Canceled breakdowns",
      today: data.canceledBreakdownSBStats.canceled_today,
      yesterday: data.canceledBreakdownSBStats.canceled_yesterday,
      dayPercentage: data.canceledBreakdownSBStats.daily_change_pct,
      thisWeek: data.canceledBreakdownSBStats.canceled_this_week,
      lastWeek: data.canceledBreakdownSBStats.canceled_last_week,
      weekPercentage: data.canceledBreakdownSBStats.weekly_change_pct,
      thisMonth: data.canceledBreakdownSBStats.canceled_this_month,
      lastMonth: data.canceledBreakdownSBStats.canceled_last_month,
      monthPercentage: data.canceledBreakdownSBStats.monthly_change_pct,
    },
    {
      key: "4",
      metric: "Value of active breakdowns",
      today: data.activeBreakdownSBValueStats.today,
      yesterday: data.activeBreakdownSBValueStats.yesterday,
      dayPercentage: data.activeBreakdownSBValueStats.daily_change_pct,
      thisWeek: data.activeBreakdownSBValueStats.this_week,
      lastWeek: data.activeBreakdownSBValueStats.last_week,
      weekPercentage: data.activeBreakdownSBValueStats.weekly_change_pct,
      thisMonth: data.activeBreakdownSBValueStats.this_month,
      lastMonth: data.activeBreakdownSBValueStats.last_month,
      monthPercentage: data.activeBreakdownSBValueStats.monthly_change_pct,
    },
  ];

  const columns = [
    {
      title: "Metric",
      dataIndex: "metric",
      key: "metric",
      width: 400,
    },
    {
      title: "Today",
      dataIndex: "today",
      key: "today",
    },
    {
      title: "Yesterday",
      dataIndex: "yesterday",
      key: "yesterday",
    },
    {
      title: "Day %",
      dataIndex: "dayPercentage",
      key: "dayPercentage",
    },
    {
      title: "This Week",
      dataIndex: "thisWeek",
      key: "thisWeek",
    },
    {
      title: "Last Week",
      dataIndex: "lastWeek",
      key: "lastWeek",
    },
    {
      title: "Week %",
      dataIndex: "weekPercentage",
      key: "weekPercentage",
    },
    {
      title: "This Month",
      dataIndex: "thisMonth",
      key: "thisMonth",
    },
    {
      title: "Last Month",
      dataIndex: "lastMonth",
      key: "lastMonth",
    },
    {
      title: "Month %",
      dataIndex: "monthPercentage",
      key: "monthPercentage",
    },
  ];

  return (
    <Table
      loading={loading}
      dataSource={data && dataSource}
      columns={columns}
      scroll={{ x: "max-content" }}
      tableLayout="fixed"
      pagination={false}
      bordered
    />
  );
};

export default BreakdownSubscriptions;

const initialDataStructure = {
  initiateBreakdownSBStats: {
    initiate_today: "0",
    initiate_yesterday: "0",
    initiate_this_week: "0",
    initiate_this_month: "0",
    initiate_last_week: "0",
    initiate_last_month: "0",
    daily_change_pct: "0%",
    weekly_change_pct: "0%",
    monthly_change_pct: "0%",
  },
  activeBreakdownSBStats: {
    active_today: "0",
    active_yesterday: "0",
    active_this_week: "0",
    active_this_month: "0",
    active_last_week: "0",
    active_last_month: "0",
    daily_change_pct: "0%",
    weekly_change_pct: "0%",
    monthly_change_pct: "0%",
  },
  canceledBreakdownSBStats: {
    canceled_today: "0",
    canceled_yesterday: "0",
    canceled_this_week: "0",
    canceled_this_month: "0",
    canceled_last_week: "0",
    canceled_last_month: "0",
    daily_change_pct: "0%",
    weekly_change_pct: "0%",
    monthly_change_pct: "0%",
  },
  activeBreakdownSBValueStats: {
    today: "0.00",
    yesterday: "0.00",
    this_week: "0.00",
    this_month: "0.00",
    last_week: "0.00",
    last_month: "0.00",
    daily_change_pct: "0%",
    weekly_change_pct: "0%",
    monthly_change_pct: "0%",
  },
};
