import React, { FC, useContext } from "react";
import { Container } from "./alertStyles";
import { Modal as AntdModal, Button } from "antd";
import { Context } from "contexts/Provider";
import { closeAlert } from "contexts/app/Actions";

export interface IAlertProps {}

const Alert: FC<IAlertProps> = () => {
  const {
    state: { app },
    dispatch,
  } = useContext(Context);

  const handleOk = () => {
    if (app.onOk) app.onOk();
    dispatch(closeAlert());
  };

  const handleCancel = () => {
    if (app.onCancel) app.onCancel();
    dispatch(closeAlert());
  };

  const footerOptions: any = {
    // case there is no function specified, no button will appear in app
    0: null,

    // case there is 1 function, 1 button will apear.
    1: [
      <Button key="submit" type="primary" onClick={handleOk}>
        {app.okText || "OK"}
      </Button>,
    ],

    // case there are 2 functions, 2 button ( default behaviour of antd app )
    2: undefined,
  };

  let footer: JSX.Element[] | null | undefined = footerOptions[0];

  if (app.onCancel && app.onOk) footer = footerOptions[2];
  else if (app.onCancel || app.onOk) footer = footerOptions[1];

  return (
    <Container>
      <AntdModal
        title={app.title}
        visible={app.isAlertVisible}
        onOk={handleOk}
        okText={app.okText || "OK"}
        onCancel={handleCancel}
        cancelText={app.cancelText || "Cancel"}
        footer={footer}
      >
        {app.content}
      </AntdModal>
    </Container>
  );
};

export default Alert;
