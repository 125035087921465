import {
  InitialState as AuthState,
  StateType as AuthStateType,
} from "./auth/State";
import {
  InitialState as AppState,
  StateType as AppStateType,
} from "./app/State";

export interface RootStateDefinition {
  auth: AuthStateType;
  app: AppStateType;
}

export const combinedState = { auth: AuthState, app: AppState };

export type RootStateType = Readonly<RootStateDefinition>;
