import React from "react";
import { Tooltip } from "antd";

interface SortableHeaderProps {
  title: string;
  columnFullname?: string;
  dataIndex: string;
  handleHeaderClick: (dataIndex: string) => void;
  getSortIndicator: (dataIndex: string) => string;
  getSortDescription: (dataIndex: string) => string;
}

const SortableHeader: React.FC<SortableHeaderProps> = ({
  title,
  columnFullname = "",
  dataIndex,
  handleHeaderClick,
  getSortIndicator,
  getSortDescription,
}: SortableHeaderProps) => (
  <Tooltip title={`${columnFullname}  ${getSortDescription(dataIndex)}`}>
    <span
      style={{ cursor: "pointer" }}
      onClick={() => handleHeaderClick(dataIndex)}
    >
      {title} {getSortIndicator(dataIndex)}
    </span>
  </Tooltip>
);

export default SortableHeader;
