import React, { FC, useCallback, useEffect, useState } from "react";
import { getUserBreakdown } from "api/Api";
import Table, { ColumnsType } from "antd/lib/table";
import { handleCopy } from "utils/index";
import { Badge, BadgeProps, Button, Col, Input, Row, Select } from "antd";
import ActionButton from "components/table/renderActionButton/RenderActionButton";
import { FaCcStripe } from "react-icons/fa";
import { SaveOutlined } from "@ant-design/icons";

export const BreakdownPage: FC = () => {
  const [breakdown, setBreakdown] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [filter, setFilter] = useState({});
  const [searchValue, setSearchValue] = useState<string>("");

  const fetchBreakdowns = useCallback(
    async ({ page = 1, limit, query = "", status, download = false }) => {
      setLoading(true);
      try {
        const response = await getUserBreakdown({
          page,
          limit,
          query,
          status,
          download,
          filter,
        });

        if (download) {
          window.open(response.downloadUrl);
        } else {
          setBreakdown(response.results);
          setCount(response.total_results);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [page, limit, filter]
  );

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      render: function idColumn(text: string) {
        return (
          <div
            style={{
              //   wordWrap: "break-word",
              //   wordBreak: "break-word",
              //   whiteSpace: "nowrap",
              //   overflow: "hidden",
              maxWidth: "25px",
              //   textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onDoubleClick={async () => await handleCopy({ text, type: "ID" })}
            title={text}
          >
            {text}
          </div>
        );
      },
      fixed: true,
      width: 5,
    },
    {
      title: "User ID",
      dataIndex: "user_id",
      render: function userIdColumn(text: string) {
        return (
          <div
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "150px",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onDoubleClick={async () =>
              await handleCopy({ text, type: "User ID" })
            }
            title={text}
          >
            {text}
          </div>
        );
      },
      fixed: true,
      width: 100,
    },
    {
      title: "E-mail",
      dataIndex: "email",
      fixed: true,
      width: 100,
      // eslint-disable-next-line react/display-name
      render: (text: string) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "200px",
            cursor: "pointer",
          }}
          title={text}
          onDoubleClick={() => handleCopy({ text, type: "Email" })}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        { text: "Active", value: "active" },
        { text: "Past Due", value: "past_due" },
        { text: "Canceled", value: "canceled" },
        { text: "Incomplete", value: "incomplete" },
        { text: "Expired", value: "incomplete_expired" },
      ],
      width: 120,
      fixed: true,
      // eslint-disable-next-line react/display-name, react/prop-types
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Select
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e ? [e] : []);
              confirm();
              setFilter((prev) => {
                return {
                  ...prev,
                  status: e,
                };
              });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            <Select.Option value="active">Active</Select.Option>
            <Select.Option value="past_due">Past Due</Select.Option>
            <Select.Option value="canceled">Canceled</Select.Option>
            <Select.Option value="incomplete">Incomplete</Select.Option>
            <Select.Option value="incomplete_expired">Expired</Select.Option>
          </Select>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              setFilter((prev) => {
                return {
                  ...prev,
                  status: "",
                };
              });
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      render: function renderStatus(text: string, record: any) {
        let renderText = "";
        let badgeStatus: BadgeProps["status"] = "default";
        const isCancelActive = record?.isCancelActive;

        switch (text) {
          case "active":
            renderText = "Active";
            break;
          case "past_due":
            renderText = "Past Due";
            break;
          case "canceled":
            renderText = "Canceled";
            break;
          case "incomplete":
            renderText = "Incomplete";
            break;
          case "incomplete_expired":
            renderText = "Expired";
            break;

          default:
            break;
        }

        switch (text) {
          case "active":
            badgeStatus = "success";
            if (isCancelActive) {
              badgeStatus = "warning";
            }
            break;
          case "past_due":
            badgeStatus = "warning";
            break;
          case "canceled":
            badgeStatus = "error";
            break;
          case "incomplete":
            badgeStatus = "processing";
            break;
          case "incomplete_expired":
            badgeStatus = "default";
            break;
          default:
            break;
        }

        return (
          <div
            style={{
              maxWidth: "200px",
            }}
            title={text}
          >
            <Badge status={badgeStatus} text={renderText} />
          </div>
        );
      },
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      render: function makeColumn(text: string) {
        return (
          <div
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "150px",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onDoubleClick={async () =>
              await handleCopy({ text, type: "Phone" })
            }
            title={text}
          >
            {text}
          </div>
        );
      },
      width: 150,
    },
    {
      title: "Registration",
      dataIndex: "registration",
      render: function registrationColumn(text: string) {
        return (
          <div
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "150px",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onDoubleClick={async () =>
              await handleCopy({ text, type: "Registration" })
            }
            title={text}
          >
            {text}
          </div>
        );
      },
      width: 150,
    },
    {
      title: "Make",
      dataIndex: "make",
      render: function makeColumn(text: string) {
        return (
          <div
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "150px",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onDoubleClick={async () => await handleCopy({ text, type: "Make" })}
            title={text}
          >
            {text}
          </div>
        );
      },
      width: 150,
    },
    {
      title: "Model",
      dataIndex: "model",
      render: function makeColumn(text: string) {
        return (
          <div
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "150px",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onDoubleClick={async () =>
              await handleCopy({ text, type: "Model" })
            }
            title={text}
          >
            {text}
          </div>
        );
      },
      width: 150,
    },
    {
      title: "Insurer Ref.",
      dataIndex: "insurer_reference",
      render: function makeColumn(text: string) {
        return (
          <div
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "150px",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onDoubleClick={async () =>
              await handleCopy({ text, type: "Insurer Reference" })
            }
            title={text}
          >
            {text}
          </div>
        );
      },
      width: 150,
    },
    {
      title: "Policy Code",
      dataIndex: "policy_code",
      render: function policyCodeColumn(text: string) {
        return (
          <div
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "150px",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onDoubleClick={async () =>
              await handleCopy({ text, type: "Policy Code" })
            }
            title={text}
          >
            {text}
          </div>
        );
      },
      width: 150,
    },
    {
      title: "Breakdown Plan Name",
      dataIndex: "breakdown_plan_name",
      render: function makeColumn(text: string) {
        return (
          <div
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "150px",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onDoubleClick={async () =>
              await handleCopy({ text, type: "Breakdown Plan Name" })
            }
            title={text}
          >
            {text}
          </div>
        );
      },
      width: 150,
    },
    {
      title: "Cancelled At",
      dataIndex: "cancelled_at",
      render: function cancelledAtColumn(text: string) {
        return (
          <div
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "150px",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onDoubleClick={async () =>
              await handleCopy({ text, type: "Breakdown Plan Name" })
            }
            title={text}
          >
            {text
              ? new Date(text).toLocaleString("en-GB", { timeZone: "UTC" })
              : text}
          </div>
        );
      },
      width: 150,
    },
    {
      title: "Cancel At",
      dataIndex: "cancel_at",
      render: function cancelAtColumn(text: string) {
        return (
          <div
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "150px",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onDoubleClick={async () =>
              await handleCopy({ text, type: "Breakdown Plan Name" })
            }
            title={text}
          >
            {text
              ? new Date(text).toLocaleString("en-GB", { timeZone: "UTC" })
              : text}
          </div>
        );
      },
      width: 150,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: function createdAtColumn(text: string) {
        return (
          <div
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "150px",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onDoubleClick={async () =>
              await handleCopy({ text, type: "Breakdown Plan Name" })
            }
            title={text}
          >
            {text
              ? new Date(text).toLocaleString("en-GB", { timeZone: "UTC" })
              : text}
          </div>
        );
      },
      width: 150,
    },
    {
      title: "Open Stripe",
      key: "open_stripe",
      // eslint-disable-next-line react/display-name
      render: (record: any) => (
        <ActionButton
          recordKey={record.subscriptionID}
          loading={loading}
          icon={
            <FaCcStripe
              style={{
                width: "1.5em",
                height: "1.5em",
              }}
            />
          }
          onClickButton={() => {
            window.open(
              `https://dashboard.stripe.com/subscriptions/${record.subscriptionID}`
            );
          }}
        />
      ),
      width: 80,
      fixed: "right",
      align: "center",
    },
  ];

  useEffect(() => {
    fetchBreakdowns({ page, limit, filter, query: searchValue });
  }, [fetchBreakdowns, page, limit, filter, searchValue]);

  return (
    <Row>
      <Col span={24}>
        <Row style={{ marginBottom: 10 }}>
          <Col span={6}>
            <Input.Search
              placeholder="Search"
              onSearch={(value) => {
                if (value) {
                  setPage(1);
                }
                setSearchValue(value);
              }}
            />
          </Col>
          <Col span={18} style={{ textAlign: "right" }}>
            <Button
              disabled={loading}
              style={{ marginRight: 18 }}
              onClick={() =>
                fetchBreakdowns({
                  page,
                  limit,
                  query: searchValue,
                  download: true,
                })
              }
            >
              <SaveOutlined />
            </Button>
          </Col>
        </Row>

        <Table
          loading={loading}
          rowKey="id"
          pagination={{
            current: page,
            pageSize: limit,
            total: count,
            onChange: (newPage, newPageSize) => {
              setPage(newPage);
              setLimit(newPageSize);
            },
            onShowSizeChange: (newPage, newPageSize) => {
              setPage(newPage);
              setLimit(newPageSize);
            },
          }}
          dataSource={breakdown}
          columns={columns}
          scroll={{ x: "max-content" }}
          tableLayout="fixed"
        />
      </Col>
    </Row>
  );
};
