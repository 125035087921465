import { notification } from "antd";

export async function handleCopy({
  text,
  type,
}: {
  text: string;
  type: string;
}): Promise<null> {
  try {
    await navigator.clipboard.writeText(text);
    notification.success({
      message: "Success",
      description: `${type} copied to clipboard.`,
      duration: 2,
    });
  } catch (error) {
    console.error("Failed to copy text:", error);
  } finally {
    return null;
  }
}
