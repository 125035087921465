import React, { FC, useEffect, useState } from "react";
import { listReferralStates } from "api/Api";
import { Row, Col, Table, Select, Input } from "antd";
import { User } from "../../types/User";

export interface IReferralPageProps {}

const ReferralPage: FC<IReferralPageProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [referralStates, setReferralStates] = useState<Array<User>>([]);
  const [count, setCount] = useState<number>(0);
  const [status, setStatus] = useState<string>("");
  const [searchVal, setSearchVal] = useState<string>("");

  const func = async ({
    page,
    status,
    email,
  }: {
    page?: number;
    status?: string;
    email?: string;
  }) => {
    setLoading(true);
    const result = await listReferralStates({ page, query: { email, status } });
    setLoading(false);
    setCount(result.data.total_results);
    setReferralStates(
      result.data.results?.map(
        ({ referee_email, referrer_email, status, created_at, brand }: any) => {
          return { referee_email, referrer_email, status, created_at, brand };
        }
      )
    );
  };

  useEffect(() => {
    func({});
  }, []);

  return (
    <>
      <Row>
        <Col offset={3} span={4}>
          <Input.Search
            placeholder="Search E-mail"
            onSearch={(val) => {
              setSearchVal(val);
              func({ status, email: val });
            }}
          />
        </Col>
        <Col offset={1} span={5} style={{ marginBottom: 10 }}>
          <Select
            placeholder="Status"
            defaultValue={null}
            value={status.length > 0 ? status : null}
            style={{ width: "80%" }}
            onChange={(e) => {
              setStatus(e);
              func({ status: e, email: searchVal });
            }}
          >
            <Select.Option value="onboarding_completed">
              Onboarding Completed
            </Select.Option>
            <Select.Option value="test_drive_completed">
              Test Drive Completed
            </Select.Option>
            <Select.Option value="purchase_completed">
              Purchase Completed
            </Select.Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={18} offset={3}>
          <Table
            loading={loading}
            pagination={{
              showSizeChanger: false,
              pageSize: 10,
              total: count,
              onChange: (page) => {
                func({ page, status, email: searchVal });
              },
            }}
            dataSource={referralStates}
            columns={[
              { title: "Referee", dataIndex: "referee_email" },
              { title: "Referee Device", dataIndex: "brand" },
              { title: "Referrer", dataIndex: "referrer_email" },
              { title: "Status", dataIndex: "status" },
              { title: "Created At", dataIndex: "created_at" },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default ReferralPage;
