import React from "react";
import { Button } from "antd";

interface ActionButtonProps {
  recordKey: string;
  loading: boolean;
  icon: any;
  data?: any;
  onClickButton: (key: string, data?: string) => void;
}

const ActionButton: any = ({
  recordKey,
  loading,
  icon,
  data,
  onClickButton,
}: ActionButtonProps) => {
  return (
    <Button
      disabled={loading}
      onClick={() => onClickButton(recordKey, data)}
      type="primary"
    >
      {icon}
    </Button>
  );
};

export default ActionButton;
