import React from "react";
import { Collapse, Row, Col } from "antd";
import DirectNewBusiness from "./DirectNewBussines";
import PartnerNewBussiness from "./PartnerNewBussiness";
import RenewalsPage from "./Renewals";
import TotalPage from "./Total";
import BreakdownSubscriptions from "./BreakdownSubscriptions";

const { Panel } = Collapse;

const DashboardPage = () => {
  return (
    <Row>
      <Col span={24} offset={0}>
        <Collapse
          defaultActiveKey={["1"]}
          style={{ backgroundColor: "##fff0f0" }}
        >
          <Panel header="Direct New Bussiness" key="1">
            <DirectNewBusiness />
          </Panel>
          <Panel header="Partner New Bussiness" key="2">
            <PartnerNewBussiness />
          </Panel>
          <Panel header="Renewals" key="3">
            <RenewalsPage />
          </Panel>
          <Panel header="Total" key="4">
            <TotalPage />
          </Panel>
          <Panel header="Breakdown Subscriptions" key="5">
            <BreakdownSubscriptions />
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default DashboardPage;
