import React, { FC, useEffect, useState } from "react";
import { listDevices } from "api/Api";
import { Row, Col, Table, Input } from "antd";

export interface IDevicesPageProps {}

const DevicesPage: FC<IDevicesPageProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [devices, setDevices] = useState<Array<any>>([]);
  const [count, setCount] = useState<number>(0);
  const [searchVal, setSearchVal] = useState<string>("");

  const func = async ({ page, query }: { page?: number; query?: any }) => {
    setLoading(true);
    const result = await listDevices({ page, query });
    setLoading(false);
    setCount(result.data.total_results);
    setDevices(
      result.data.results.map(
        ({
          id,
          email,
          brand,
          model,
          system_type,
          system_version,
          latest_app_version,
          ios_advertising_id,
          android_advertising_id,
          notification,
          android_activity_recognition,
          anroid_write_external_storage,
          android_access_fine_location,
          android_access_background_location,
          ios_motion,
          ios_location_always,
          ios_location_when_in_use,
        }: any) => {
          return {
            key: id,
            email,
            brand,
            model,
            system_type,
            system_version,
            latest_app_version,
            ios_advertising_id,
            android_advertising_id,
            notification,
            android_activity_recognition,
            anroid_write_external_storage,
            android_access_fine_location,
            android_access_background_location,
            ios_motion,
            ios_location_always,
            ios_location_when_in_use,
          };
        }
      )
    );
  };

  useEffect(() => {
    func({});
  }, []);

  return (
    <Row>
      <Col span={18} offset={3}>
        <Col span={6} style={{ marginBottom: 10 }}>
          <Input.Search
            placeholder="Search"
            onSearch={(val) => {
              setSearchVal(val);
              func({ query: val });
            }}
          />
        </Col>
        <Table
          scroll={{ x: 600 }}
          loading={loading}
          pagination={{
            showSizeChanger: false,
            pageSize: 10,
            total: count,
            onChange: (page) => {
              func({ page, query: searchVal });
            },
          }}
          dataSource={devices}
          columns={[
            { title: "E-mail", dataIndex: "email" },
            { title: "Brand", dataIndex: "brand" },
            { title: "Model", dataIndex: "model" },
            { title: "System Type", dataIndex: "system_type" },
            { title: "System Version", dataIndex: "system_version" },
            { title: "App Version", dataIndex: "latest_app_version" },
            { title: "IOS Advertisment Id", dataIndex: "ios_advertising_id" },
            {
              title: "Android Advertisment Id",
              dataIndex: "android_advertising_id",
            },
            { title: "Notification Permission", dataIndex: "notification" },
            {
              title: "Android Activity Permission",
              dataIndex: "android_activity_recognition",
            },
            {
              title: "Android Storage Permission",
              dataIndex: "anroid_write_external_storage",
            },
            {
              title: "Android Location Permission",
              dataIndex: "android_access_fine_location",
            },
            {
              title: "Android Background Location Permission",
              dataIndex: "android_access_background_location",
            },
            { title: "IOS Motion Permission", dataIndex: "ios_motion" },
            {
              title: "IOS Location Always Permission",
              dataIndex: "ios_location_always",
            },
            {
              title: "IOS Location In Use Permission",
              dataIndex: "ios_location_when_in_use",
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default DevicesPage;
